import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./Form.module.scss";
import offer from "../../../assets/images/offer.png";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const formSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Full Name Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(50, "Too Long!")
    .required("Phone Number Required"),
  course: Yup.string().required("Please select a course"),
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const Form = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const courseList = ["Gate", "B.Tech", "ISRO", "ESE"];

  const inputStyle = {
    borderRadius: 1,
    borderColor: "#F5F7F9",
    backgroundColor: "#040714",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& label": {
      fontFamily: "Manrope",
      fontWeight: 500,
      color: "#646464",
    },
    "& input": {
      fontFamily: "Manrope",
      fontWeight: 500,
      color: "#FFFFFF",
    },
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      course: "",
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        fullName: values?.fullName,
        phoneNumber: values?.phoneNumber,
        course: values?.course,
        email: values?.email,
      };

      // Simulate form submission success
      const status = formData; // Replace this with actual API call if needed

      if (status) {
        setLoading(false);

        // Display toast only after successful submission
        toast.success("Submitted! We will contact you shortly.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Adjust timing as needed
        });

        // Redirect based on the selected course
        setTimeout(() => {
          switch (formData.course) {
            case "Gate":
              window.open(
                "https://learn.msigmagokulam.com/branches/5",
                "_blank"
              );
              break;
            case "B.Tech":
              window.open(
                "https://learn.msigmagokulam.com/branches/2",
                "_blank"
              );
              break;
            case "ISRO":
              window.open(
                "https://learn.msigmagokulam.com/branches/4",
                "_blank"
              );
              break;
            case "ESE":
              window.open(
                "https://learn.msigmagokulam.com/branches/6",
                "_blank"
              );
              break;
            default:
              break;
          }
        }, 3000);

        // Reset form after successful submission
        resetForm({
          values: {
            fullName: "",
            phoneNumber: "",
            course: "",
            email: "",
          },
        });
      }
    },

    validationSchema: formSchema,
  });

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#040714",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          width: "100%",
          px: { xs: 4, sm: 12, md: 20 },
          position: "absolute",
          mt: { xs: -54, sm: -58, md: -36 },
        }}
      >
        <img src={offer} alt="" width="100%" />
      </Grid>

      <Grid
        item
        className={style.deskTopWrapper}
        sx={{
          px: { xs: 4, sm: 12, md: 20 },
          py: { xs: 6, sm: 10, md: 18 },
          mt: { xs: 10, sm: 16, md: 32 },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "end",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "38%" },
                gap: 4,
              }}
            >
              <>
                <Typography
                  sx={{ color: "#fc0303", fontFamily: "Manrope", mb: -4 }}
                >
                  {formik.touched.fullName && formik.errors.fullName}
                </Typography>
                <TextField
                  value={formik.values.fullName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="fullName"
                  name="fullName"
                  label="Name"
                  variant="outlined"
                  sx={inputStyle}
                />
              </>

              <>
                <Typography
                  sx={{ color: "#fc0303", fontFamily: "Manrope", mb: -4 }}
                >
                  {formik.touched.email && formik.errors.email}
                </Typography>
                <TextField
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  sx={inputStyle}
                />
              </>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "38%" },
                gap: 4,
              }}
            >
              <Typography
                sx={{ color: "#fc0303", fontFamily: "Manrope", mb: -4 }}
              >
                {formik.touched.course && formik.errors.course}
              </Typography>
              <FormControl fullWidth>
                <InputLabel
                  id="course-select-label"
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    color: "#646464",
                  }}
                >
                  Select course you’re interested in
                </InputLabel>
                <Select
                  labelId="course-select-label"
                  id="course-select"
                  value={formik.values.course}
                  onChange={(event) =>
                    formik.setFieldValue("course", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  label="Select course you’re interested in"
                  renderValue={(selected) => (
                    <span style={{ color: "#ffffff" }}>{selected}</span>
                  )}
                  sx={{
                    width: "100%",
                    backgroundColor: "#040714",
                    color: "#ffffff",
                    border: "none",
                    "&:focus": {
                      backgroundColor: "#040714",
                      outline: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiSelect-icon": {
                      color: "#ffffff",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#040714",
                        color: "#ffffff",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: "#333333",
                            color: "#ffffff",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#040714",
                            color: "#ffffff",
                            "&:hover": {
                              backgroundColor: "#333333",
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  {courseList.map((courses, index) => (
                    <MenuItem
                      key={index}
                      value={courses}
                      sx={{
                        fontFamily: "Manrope",
                        backgroundColor: "#040714",
                        color: "#646464",
                        "&:hover": {
                          backgroundColor: "#333333",
                          color: "#ffffff",
                        },
                        "&.Mui-selected": {
                          backgroundColor: "#040714",
                          color: "#ffffff",
                          "&:hover": {
                            backgroundColor: "#333333",
                          },
                        },
                      }}
                    >
                      {courses}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <>
                <Typography
                  sx={{ color: "#fc0303", fontFamily: "Manrope", mb: -4 }}
                >
                  {formik.touched.phoneNumber && formik.errors.phoneNumber}
                </Typography>
                <TextField
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  sx={inputStyle}
                />
              </>
            </Box>
            <Button
              type="submit"
              style={{
                backgroundColor: "#3CBA80",
              }}
              sx={{
                borderRadius: 1,
                py: { sm: 0.7, md: 1.7 },
                color: "#FFFFFF",
                textAlign: "center",
                gap: 0.7,
                px: { sm: 1, md: 2 },
                fontSize: { sm: 14, md: 16 },
                textTransform: "none",
                fontWeight: 400,
                fontFamily: "Manrope",
                width: { xs: "40%", sm: "30%", md: "20%" },
              }}
            >
              Enroll Now!
            </Button>
            <ToastContainer />
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
};

export default Form;
