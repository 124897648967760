import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import { MdStar } from "react-icons/md";
import shape from "../../../assets/icons/shape.png";
import blueshape from "../../../assets/icons/blueshape.png";
import books from "../../../assets/images/books.png";
import stars from "../../../assets/icons/stars.png";
import fb from "../../../assets/icons/facebook.png";
import ig from "../../../assets/icons/insta.png";
import ln from "../../../assets/icons/ln.png";
import { useParams } from "react-router-dom";
import { facultyDetails } from "./FacProfile.content";

const FacProfile = () => {
  const { id } = useParams();
  const faculty = facultyDetails.find((p) => p.id === parseInt(id));

  return (
    <>
      <Box sx={{ mt: 6 }}>
        <Grid
          container
          sx={{
            width: "100%",
            px: { xs: 4, md: 12 },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {faculty && (
            <>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    md: "40%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  },
                }}
              >
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { xs: "28px", md: "32px", lg: "40px" },
                    fontWeight: "500",
                    lineHeight: 1.2,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {faculty.name}
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: "12px", md: "16px" },
                    fontWeight: "500",
                  }}
                >
                  {faculty.designation}
                </Typography>
                <img
                  src={faculty.profilePhoto}
                  alt={faculty.name}
                  width="350px"
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "100%",
                    md: "60%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  },
                }}
              >
                {faculty.aboutMe && (
                  <>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { md: "12px", lg: "14px" },
                        fontWeight: "500",
                        color: "#8B8B8B",
                        mt: { xs: 2 },
                      }}
                    >
                      About Me
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "13px", md: "16px", lg: "20px" },
                        fontWeight: "500",
                        lineHeight: "37px",
                        textAlign: "justify",
                      }}
                    >
                      {faculty.aboutMe}
                    </Typography>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: 2,
                    mt: 2,
                    mb: { xs: 8, md: 12, lg: 0 },
                  }}
                >
                  {faculty.urls?.linkedin && (
                    <a
                      href={faculty.urls.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={ln} alt="LinkedIn" width="36px" />
                    </a>
                  )}
                  {faculty.urls?.instagram && (
                    <a
                      href={faculty.urls.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={ig} alt="Instagram" width="36px" />
                    </a>
                  )}
                  {faculty.urls?.facebook && (
                    <a
                      href={faculty.urls.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fb} alt="Facebook" width="36px" />
                    </a>
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        {faculty.academics.length > 0 && (
          <Grid
            container
            sx={{
              backgroundColor: "#F6F6EC",
              display: "flex",
              flexDirection: "column",
              pb: 5,
            }}
          >
            <Grid item sx={{ pl: { xs: 8, md: 26 } }}>
              <img src={shape} alt="Shape" width="40px" />
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { md: "16px", lg: "20px" },
                  fontWeight: "600",
                  px: { xs: 6, md: 12 },
                  mt: -3.5,
                }}
              >
                Academic Qualifications
              </Typography>
            </Grid>

            <Grid container sx={{ px: { xs: 4, md: 36 } }}>
              {faculty?.academics?.map((academy) => (
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      gap: 1,
                      mt: 2,
                    }}
                  >
                    <MdStar style={{ color: "#3878F2", fontSize: "18px" }} />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "14px", lg: "16px" },
                        fontWeight: "600",
                      }}
                    >
                      {academy?.qualifications}
                    </Typography>
                  </Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "13px", lg: "15px" },
                      fontWeight: "500",
                      pl: 3,
                    }}
                  >
                    {academy?.institute}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {faculty.experience.length > 0 && (
          <Grid
            container
            sx={{
              width: "100%",
              px: { xs: 4, md: 12 },
              my: { xs: 4, md: 8 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { xs: "16px", lg: "20px" },
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                Professional Experience
              </Typography>
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                mt: 2,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {faculty?.experience?.map((expr, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Box
                    sx={{
                      backgroundColor: "#F5F5FF",
                      p: { xs: 2, md: 6 },
                      borderRadius: "5px",
                      mt: { xs: 2, md: 3, lg: 0 },
                      height: { lg: "350px" },
                    }}
                  >
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "14px", md: "16px", lg: "18px" },
                        fontWeight: "700",
                      }}
                    >
                      {expr?.company}
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "13px", md: "15px", lg: "16px" },
                        fontWeight: "600",
                        mt: 1,
                      }}
                    >
                      {expr?.position}
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "12px", md: "14px", lg: "15px" },
                        fontWeight: "500",
                        mt: 2,
                        lineHeight: "36px",
                      }}
                    >
                      {expr?.details}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}

        {faculty.expertise.length > 0 && (
          <Grid
            container
            sx={{
              width: "100%",
              px: { xs: 4, md: 12 },
              my: { xs: 4, md: 8 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: { xs: 10, lg: 6 },
            }}
          >
            <Grid item sx={{ width: "100%" }}>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { md: "16px", lg: "20px" },
                  fontWeight: "600",
                  textAlign: "center",
                  mb: 3,
                }}
              >
                Areas of Expertise
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                width: "100%",
                gap: { xs: 3, lg: 6 },
              }}
            >
              {faculty?.expertise?.map((expert) => (
                <Card
                  sx={{
                    width: 280,
                    height: 244,
                    boxShadow: "none",
                    borderRadius: "5px",
                    position: "relative", // Ensure the overlay is positioned correctly
                  }}
                >
                  <CardMedia
                    component="img"
                    image={expert?.image}
                    loading="lazy"
                    alt="Chairman"
                    sx={{ height: 244, width: 280 }}
                  />
                  {/* Transparent background box */}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      width: "100%",
                      height: 60,
                      bgcolor: "rgba(0, 0, 0, 0.55)", 
                    }}
                  />
                  <CardContent
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: 280,
                      padding: 1,
                    }}
                  >
                    <Typography
                      fontFamily="Manrope"
                      fontWeight="500"
                      textAlign="start"
                      sx={{
                        fontSize: "14px",
                        color: "#ffffff",
                      }}
                    >
                      {expert?.title}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Grid>
          </Grid>
        )}

        {faculty.achievements.length > 0 && (
          <Grid
            container
            spacing={3}
            sx={{
              backgroundColor: "#F5FAFF",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              p: { xs: 4, md: 12 },
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { md: "16px", lg: "20px" },
                    fontWeight: "600",
                    textAlign: "start",
                  }}
                >
                  Achievements and Contributions
                </Typography>
              </Box>
            </Grid>

            {faculty?.achievements?.map((achieve) => (
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: 1,
                  }}
                >
                  <img src={blueshape} alt="Shape" width="20px" />
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "14px", md: "16px", lg: "18px" },
                      fontWeight: "600",
                      textAlign: "start",
                    }}
                  >
                    {achieve?.achievement}
                  </Typography>{" "}
                  <br />
                </Box>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: "13px", md: "15px", lg: "16px" },
                    fontWeight: "400",
                    textAlign: "start",
                    lineHeight: "33px",
                  }}
                >
                  {achieve?.description}
                </Typography>
              </Grid>
            ))}

            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  width: { xs: "150px", lg: "250px" },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={stars} alt="" width="100%" />
              </Box>
            </Grid>
          </Grid>
        )}

        {faculty.publications.length > 0 && (
          <Grid
            container
            spacing={3}
            sx={{
              backgroundColor: "#F5F5FF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 6,
              p: { xs: 4, md: 12 },
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { md: "16px", lg: "20px" },
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Publications and Research Work
                </Typography>
                {faculty?.publications?.map((publication) => (
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "13px", md: "15px", lg: "16px" },
                      fontWeight: "400",
                      textAlign: "center",
                      lineHeight: "36px",
                    }}
                  >
                    {publication}
                  </Typography>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box
                sx={{
                  width: "300px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={books} alt="" width="300px" />
              </Box>
            </Grid>
          </Grid>
        )}

        {faculty.affiliations.length > 0 && (
          <Box sx={{ px: { xs: 4, md: 12 }, mt: { xs: 12, md: 15, lg: 16 } }}>
            <Grid
              container
              sx={{
                backgroundColor: "#FFF5FB",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: { xs: 4, md: 8 },
              }}
            >
              <Grid item xs={12} sm={6} sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { md: "16px", lg: "20px" },
                      fontWeight: "700",
                    }}
                  >
                    Professional Affiliations and Memberships
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { md: "14px", lg: "16px" },
                      fontWeight: "400",
                    }}
                  >
                    Affiliations with Professional Bodies
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: { xs: "column", sm: "row", md: "row" },
                  width: "100%",
                  gap: 4,
                  mt: 2,
                  px: { xs: 2, md: 6 },
                }}
              >
                {faculty?.affiliations?.map((affiliation) => (
                  <Box
                    sx={{
                      width: { xs: "100%", lg: "50%" },
                      backgroundColor: "#FFE3F3",
                      p: { xs: 2, md: 4 },
                      px: { xs: 4, md: 8 },
                      borderRadius: "5px",
                    }}
                  >
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "13px", md: "15px", lg: "16px" },
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      {affiliation}
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default FacProfile;
