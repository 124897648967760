import manu from "../../../assets/images/manu.png";
import ranghanathan from "../../../assets/images/rang.png";
import shekhar from "../../../assets/images/shekh.png";
import charly from "../../../assets/images/charly.png";
import nithin from "../../../assets/images/nithin.png";
import prasanna from "../../../assets/images/prasanna.png";
import athira from "../../../assets/images/athiramiss.png";
import arya from "../../../assets/images/aryadutt.png";
import akhila from "../../../assets/images/akhila.png";
import anoop from "../../../assets/images/anoop.png";
import bala from "../../../assets/images/bala.png";
import betharaju from "../../../assets/images/betharaju.png";
import betsy from "../../../assets/images/betsy.png";
import hari from "../../../assets/images/hari.png";
import naveen from "../../../assets/images/naveen.png";
import padmaja from "../../../assets/images/padmaja.png";
import raghav from "../../../assets/images/raghav.png";
import rajarao from "../../../assets/images/rajarao.png";
import reddy from "../../../assets/images/reddy.png";
import roshan from "../../../assets/images/roshan.png";
import trinath from "../../../assets/images/trinath.png";
import vinod from "../../../assets/images/vinod.png";
import vishnu from "../../../assets/images/vishnu.png";
import renjan from "../../../assets/images/renjanRoy.png";
import empty from "../../../assets/images/empty.png";
import arjun from "../../../assets/images/arjunsir.png";
import dipin from "../../../assets/images/dipin.png";
import suresh from "../../../assets/images/suresh.png";
import mojya from "../../../assets/images/mojya.png";
import afsal from "../../../assets/images/Afsal.png";
import radhika from "../../../assets/images/Radhika Panickar.png";
import anju from "../../../assets/images/Anju EC.png";
import murali from "../../../assets/images/MuraliKrishna.png";
import abhijin from "../../../assets/images/Abhijin.png";
import prashanth from "../../../assets/images/Prashnath kumar.png";

export const allMasters = [
  { 
    id:1,
    name: "Manu M Rajan",
    position: "The CEO & Executive Director Msigma Gokulam",
    image: manu,
  },
  {
    id:2,
    name: "Dr. K Renganathan",
    position: "ISRO G level Scientist",
    image: ranghanathan,
  },
  {
    id:3,
    name: "A V V B  Sekhar",
    position: "Man Behind 100+ Single Digit Ranks in Gate and ESE",
    image: shekhar,
  },
  {
    id:4,
    name: "Charlies Leo",
    position: "Former Military Engineering Service",
    image: charly,
  },
  {
    id:5,
    name: "Rajarao M V [SHASTRI]",
    position: "Ex IES officer (All India Rank 6)",
    image: rajarao,
  },
  {
    id:6,
    name: "V S R Suresh",
    position: "India's No 1 Facuilty in multiple Electronics Subjects",
    image: suresh,
  },
  {
    id:7,
    name: "Trinath Reddy",
    position: "23+ Years of Experience and Over a lakh of students",
    image: trinath,
  },
  {
    id:8,
    name: "T Nithin",
    position: "Consecutive GATE Topper Under AIR 100",
    image: nithin,
  },
  {
    id:9,
    name: "V. Bala Murali Krishna",
    position: "Best Competitive mathematics faculity in India",
    image: bala,
  },
  {
    id:10,
    name: "Vaka Raghav Reddy",
    position: "GATE Single Digit Rank Holder ",
    image: raghav,
  },
  {
    id:11,
    name: "Prasanna Kumar",
    position: "IIT Graduate ",
    image: prasanna,
  },
  {
    id:12,
    name: "Ranga Reddy",
    position: "IIT Graduate ",
    image: reddy,
  },
  {
    id:13,
    name: "Naveen Gollapally",
    position: "Prominent GATE Faculty",
    image: naveen,
  },
  {
    id:14,
    name: "Betharaju Surendra Yadav",
    position: "Prominent GATE Faculty",
    image: betharaju,
  },
  {
    id:15,
    name: "G Vinod Kumar",
    position: "IIT Graduate",
    image: vinod,
  },
  {
    id:16,
    name: "B Mojya",
    position: "Prominent GATE Faculty",
    image: mojya,
  },
  {
    id:17,
    name: "Muralikrishna Bukkasamudram",
    position: "Prominent GATE Faculty",
    image: murali,
  },
  {
    id:18,
    name: "Prasanth Kumar kandregula",
    position: "Prominent GATE Faculty",
    image: prashanth,
  },
  {
    id:19,
    name: "Abhijin Sreekantan",
    position: "Prominent GATE Faculty",
    image: abhijin,
  },
  {
    id:20,
    name: "Vishnu Vardhan",
    position: "Prominent GATE Faculty",
    image: vishnu,
  },
  
  {
    id:21,
    name: "Dipin Venugopal",
    position: "GATE Rank Holder",
    image: dipin,
  },
  {
    id:22,
    name: "Arjun M A",
    position: "GATE Rank Holder",
    image: arjun,
  },
  {
    id:23,
    name: "Athira A",
    position: "GATE Rank Holder",
    image: athira,
  },
  {
    id:24,
    name: "Aryadutt C S",
    position: "GATE Rank Holder",
    image: arya,
  },
  {
    id:25,
    name: "Afsal Haleef",
    position: "GATE Rank Holder",
    image: afsal,
  },
  {
    id:26,
    name: "Renjen Roy R U",
    position: "GATE Rank Holder",
    image: renjan,
  },
  {
    id:27,
    name: "Padmaja P L",
    position: "GATE Rank Holder",
    image: padmaja,
  },
  {
    id:28,
    name: "Akhila Raj",
    position: "GATE Rank Holder",
    image: akhila,
  },
  {
    id:29,
    name: "Betsy Victor",
    position: "GATE Rank Holder",
    image: betsy,
  },
  {
    id:30,
    name: "Radhika Panickar",
    position: "GATE Rank Holder",
    image: radhika,
  },
  {
    id:31,
    name: "Anju EC",
    position: "GATE Rank Holder",
    image: anju,
  },
  {
    id:32,
    name: "Roshan Alex",
    position: "GATE Rank Holder",
    image: roshan,
  },
  {
    id:33,
    name: "Harikrishnan",
    position: "CINO",
    image: hari,
  },
  {
    id:34,
    name: "Anoop Baby",
    position: "GATE Rank Holder",
    image: anoop,
  },
];