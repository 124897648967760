import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  Button,
  CardContent,
} from "@mui/material";
import style from "./Masters.module.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import { allMasters } from "./Masters.content";
import { Link } from "react-router-dom";

const breakpoints = {
  values: {
    xs: 0,
    sm: 620,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const Masters = () => {
  const [displayedCards, setDisplayedCards] = useState(allMasters.slice(0, 3));
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setDisplayedCards(allMasters);
    setShowAll(true);
  };

  const handleShowLess = () => {
    setDisplayedCards(allMasters.slice(0, 3));
    setShowAll(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper} sx={{ my: 12 }}>
        <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: "30px", md: "34px" }, fontWeight: 400 }}
                >
                  Meet the
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { xs: "30px", md: "34px" },
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Masters
                </Typography>
              </Grid>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { md: "14px", lg: "16px" },
                  fontWeight: 400,
                  textAlign: "center",
                  px: { xs: 3, sm: 10, md: 40 },
                  mt: 2,
                  lineHeight: "35px",
                }}
              >
                Not just highly skilled, our teachers are highly sincere and
                devoted to teaching and to help you achieve your dreams.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            {allMasters.map((member, index) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={2.4}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link to={`/faculty-profile/${member.id}`}>
                  <Card
                    sx={{
                      width: 230,
                      height: 410,
                      borderRadius: 0,
                      boxShadow: "none",
                    }}
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <CardMedia
                      component="img"
                      image={member.image}
                      loading="lazy"
                      alt="Chairman"
                      sx={{ height: 300, width: 230 }}
                    />
                    <CardContent>
                      <Typography
                        fontFamily="Manrope"
                        fontWeight="500"
                        textAlign="center"
                        component="div"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#000000",
                        }}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        fontFamily="Manrope"
                        fontWeight="400"
                        textAlign="center"
                        component="div"
                        sx={{ fontSize: "14px", color: "#000000" }}
                      >
                        {member.position}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>

      <Box className={style.responsiveWrapper} sx={{ mb: 6, mt: 12 }}>
        <Grid container sx={{ width: "100%", px: 4 }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: "28px", fontWeight: 400 }}
                >
                  Meet the
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: "28px",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Masters
                </Typography>
              </Grid>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  textAlign: "start",
                  mt: 2,
                }}
              >
                Not just highly skilled, our teachers are highly sincere and
                devoted to teaching and to help you achieve your dreams.
              </Typography>
            </Grid>
          </Grid>
          <Grid container marginTop="30px" spacing={2}>
            {displayedCards.map((member, index) => (
              <Grid
                item
                xs={12}
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Link to={`/faculty-profile/${member.id}`}>
                  <Card
                    sx={{
                      width: 230,
                      height: 390,
                      borderRadius: 0,
                      boxShadow: "none",
                    }}
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <CardMedia
                      component="img"
                      image={member.image}
                      alt="Chairman"
                      sx={{ height: 300, width: 230 }}
                    />
                    <CardContent>
                      <Typography
                        fontFamily="Manrope"
                        textAlign="center"
                        component="div"
                        sx={{
                          fontSize: 18,
                          fontWeight: 500,
                          color: "#000000",
                        }}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        fontFamily="Manrope"
                        fontWeight="400"
                        textAlign="center"
                        component="div"
                        sx={{ fontSize: 14, color: "#000000" }}
                      >
                        {member.position}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
            {!showAll ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    px: 3,
                    fontSize: 14,
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  onClick={handleShowMore}
                >
                  View All
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  mt: 2,
                }}
              >
                <Button
                  fontFamily="Manrope"
                  style={{
                    backgroundColor: "#F0F0F0",
                  }}
                  sx={{
                    borderRadius: 15,
                    py: 1,
                    color: "#000000",
                    textAlign: "center",
                    px: 3,
                    fontSize: 14,
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  onClick={handleShowLess}
                >
                  View Less
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Masters;
