import FacProfile from 'components/FacultyProfile/Profile/FacProfile'
import React from 'react'

const FacultyProfile = () => {
  return (
    <div>
      <FacProfile/>
    </div>
  )
}

export default FacultyProfile
