import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";

import Preloader from "components-shared/Preloader/Preloader";
import HomePage from "Pages/HomePage/HomePage";
import AboutUs from "Pages/AboutUs";
import Courses from "Pages/Courses";
import ContactUs from "Pages/ContactUs";
import Hiring from "Pages/Hiring";
import PrivacyPolicy from "Pages/PrivacyPolicy";
import TermsConditions from "Pages/Terms/TermsConditions";
// import Launch from "Pages/Launch/";
// import Registration from "Pages/Registration/";
// import GetTickets from "Pages/GetTickets/";
import KeamNavigator from "Pages/KeamNavigator/";
import FreePythonClass from "Pages/FreePythonClass/";

import MechanicalGate from "Pages/CourseDetail/Gate/Mech/MechanicalGate";
import EceGate from "Pages/CourseDetail/Gate/ECE/EceGate";
import CivilGate from "Pages/CourseDetail/Gate/Civil/CivilGate";
import ElectricalGate from "Pages/CourseDetail/Gate/EEE/ElectricalGate";
import InstrumentationGate from "Pages/CourseDetail/Gate/IE/InstrumentationGate";
import IsroDetails from "Pages/CourseDetail/ISRO/IsroDetails";
import BtechDetail from "Pages/CourseDetail/Btech/BtechDetail";
import QrCode from "components/QrCode/QrCode";
import CollgPredictor from "components/CollgPredictor";
import CampusAmbassador from "Pages/CampusAmbassador";
import PlacementDrive from "Pages/PlacementDrive";
import Placement from "Pages/Placement";
import FacultyProfile from "Pages/FacProfile/FacultyProfile";
import Blog from "Pages/Blog";
import FreeCourse from "Pages/FreeCourse";
import ZeroToInfinity from "Pages/ZeroToInfinityPage";


const CouresDetails = lazy(() => import("Pages/CourseDetail/Gate/GateDetails"));

// const Engineering_Mechanics = lazy(() => import("Pages/Engineering_Mechanics"));
// const Thermodynamics = lazy(() => import("Pages/Thermodynamics"));
// const HeatnMassTransfer = lazy(() => import("Pages/HeatnMassTransfer"));

export default function AppRouter() {
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/gate" element={<CouresDetails />} />
        <Route path="/gate/me" element={<MechanicalGate />} />
        <Route path="/gate/ece" element={<EceGate />} />
        <Route path="/gate/ce" element={<CivilGate />} />
        <Route path="/gate/eee" element={<ElectricalGate />} />
        <Route path="/gate/ie" element={<InstrumentationGate />} />
        <Route path="/isro" element={<IsroDetails />} />
        <Route path="/btech" element={<BtechDetail />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/hiring" element={<PlacementDrive />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/qr" element={<QrCode />} />
        <Route path="/college" element={<CollgPredictor />} />
        <Route path="/campus-ambassador" element={<CampusAmbassador />} />
        <Route path="/keam-navigator" element={<KeamNavigator />} />
        <Route path="/placement-drive" element={<PlacementDrive />} />
        {/* <Route path="/blogs" element={<Blog />} /> */}
        {/* <Route path="/free-course" element={<FreeCourse />} /> */}
        <Route path="/placement-drive/:jobId" element={<Placement />} />
        <Route path="/faculty-profile/:id" element={<FacultyProfile />} />
        <Route path={routes.FreePythonClass} element={<FreePythonClass />} />

        <Route path="/zero-to-infinity" element={<ZeroToInfinity />} />
      
        {/* <Route
          path="/engineering_mechanics"
          element={<Engineering_Mechanics />}
        />
        <Route path="/thermodynamics" element={<Thermodynamics />} />
        <Route path="/heat_mass_transfer" element={<HeatnMassTransfer />} />
        <Route path="/launch" element={<Launch />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/getTickets" element={<GetTickets />} /> */}
      </Routes>
    </Suspense>
  );
}
