export const ziData = [
    {
        slNO: 1,
        title:'Classes for B.Tech Courses',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 2,
        title:'Coaching for Competitive Exams ',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 3,
        title:'Internships during All Semesters',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 4,
        title:'Industrial visits Every Year',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 5,
        title:'Industrial Training',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 6,
        title:'Coding',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 7,
        title:'Campus Placement at Msigma Gokulam',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 8,
        title:'Interview Training',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 9,
        title:'Placement Assistance',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 10,
        title:'Assistance for Internal Exams',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 11,
        title:'Aptitude Training',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 12,
        title:'Personality Development',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 13,
        title:'Communication Development ',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 14,
        title:'Model Exams',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 15,
        title:'AI Training',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 16,
        title:'Soft Skills',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },
    {
        slNO: 17,
        title:'Life Skills ',
        description:'We provide lessons as per the KTU syllabus and the students can access them through the Msigma Gokulam app available on PlayStore and AppStore.The classes are led by our expert faculty who have years of experience in the teaching field and guide you in professional growth apart from ensuring success in exams.'
    },

]