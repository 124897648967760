import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  InputAdornment,
  TextField,
  FormControl,
} from "@mui/material";
import style from "./Blog.module.scss";
import { PiArrowDownRightBold } from "react-icons/pi";
import blog1 from "../../../assets/images/bl1.png";
import blog2 from "../../../assets/images/bl2.png";
import blog3 from "../../../assets/images/bl3.png";
import blog4 from "../../../assets/images/bl4.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const formSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/blogs");
  };

  // formik
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        email: values?.email,
      };

      const status = formData;
      if (status) {
        setLoading(false);
        alert("Submitted, we will contact you");
        
        resetForm({
          values: {
            email: "",
          },
        });
      }
    },
    validationSchema: formSchema,
  });

  return (
    <>
      <Box className={style.deskTopWrapper} sx={{ py: 12 }}>
        <Grid container sx={{ width: "100%", px: 12 }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: "34px", fontWeight: 400 }}
                >
                  Subscribe Now for Exclusive Updates and Offers
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 2,
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <FormControl
                    sx={{
                      display: {
                        sm: "none",
                        md: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Typography
                      sx={{ color: "#fc0303", fontFamily: "Manrope", ml: 1 }}
                    >
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                    <TextField
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                      variant="outlined"
                      placeholder="Enter Email Id"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              fontSize: 20,
                              backgroundColor: "#DDDDDB",
                              mr: -1.2,
                              py: 2,
                              px: 1,
                              borderRadius: 6,
                            }}
                          >
                            <Button
                              type="submit" // Set button type as submit
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Manrope",
                                color: "#000000",
                                textTransform: "none",
                              }}
                            >
                              Subscribe
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Manrope",
                                  color: "#000000",
                                  backgroundColor: "#ffffff",
                                  p: 0.3,
                                  px: 0.8,
                                  borderRadius: "50%",
                                  mr: -1.7,
                                  ml: 2,
                                  mt: 0.1,
                                }}
                              >
                                <PiArrowDownRightBold />
                              </Typography>
                            </Button>
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "30px",
                          width: "330px",
                          height: "40px",
                          display: { sm: "none", md: "flex" },
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontFamily: "Manrope",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontFamily: "Manrope",
                        },
                      }}
                    />
                  </FormControl>
                </form>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                mt: 8,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "34px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{
                  fontSize: "34px",
                  fontWeight: 400,
                  fontStyle: "italic",
                }}
              >
                Blogs
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginTop="30px"
            sx={{ display: "flex", width: "100%", alignItems: "start" }}
          >
            <Grid
              item
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <img src={blog1} alt="" width="640px" />
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "16px", fontWeight: 400, mt: 1 }}
              >
                Msigma Group | 03 June 2024
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "24px", fontWeight: 600, mt: 1 }}
              >
                Unlock Your Potential: Mastering the GATE Exam
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "16px", fontWeight: 400, mt: 1 }}
              >
                Are you ready to pave your way to success? Dive into our
                comprehensive blog series designed to guide you through the
                intricacies of the GATE exam.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
                gap: 1,
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <Box>
                  <img src={blog2} alt="" width="280px" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                  >
                    Msigma Group | 03 June 2024
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "20px", fontWeight: 600, mt: 1 }}
                  >
                    Unlock Your Potential: Mastering the GATE Exam
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "14px", fontWeight: 400, mt: 1 }}
                  >
                    Are you ready to pave your way to success? Dive into our
                    comprehensive blog series designed to guide you through the
                    intricacies of the GATE exam.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <Box>
                  <img src={blog3} alt="" width="280px" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                  >
                    Msigma Group | 03 June 2024
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "20px", fontWeight: 600, mt: 1 }}
                  >
                    Unlock Your Potential: Mastering the GATE Exam
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "14px", fontWeight: 400, mt: 1 }}
                  >
                    Are you ready to pave your way to success? Dive into our
                    comprehensive blog series designed to guide you through the
                    intricacies of the GATE exam.
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "start",
                  gap: 2,
                }}
              >
                <Box>
                  <img src={blog4} alt="" width="280px" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "15px", fontWeight: 400 }}
                  >
                    Msigma Group | 03 June 2024
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "20px", fontWeight: 600, mt: 1 }}
                  >
                    Unlock Your Potential: Mastering the GATE Exam
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "14px", fontWeight: 400, mt: 1 }}
                  >
                    Are you ready to pave your way to success? Dive into our
                    comprehensive blog series designed to guide you through the
                    intricacies of the GATE exam.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleButtonClick}
              fontFamily="Manrope"
              style={{
                backgroundColor: "#ffffff",
              }}
              sx={{
                borderRadius: 15,
                py: 1.5,
                color: "#000000",
                textAlign: "center",
                px: 2,
                fontSize: "16px",
                textTransform: "none",
                mt: 4,
                fontWeight: 500,
                width: "162px",
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box className={style.responsiveWrapper} sx={{ py: 6 }}>
        <Grid container sx={{ width: "100%", px: 3 }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: "24px",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Subscribe Now for Exclusive Updates and Offers
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 2,
                }}
              >
                <form onSubmit={formik.handleSubmit}>
                  <FormControl
                    sx={{
                      display: {
                        sm: "none",
                        md: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Typography
                      sx={{ color: "#fc0303", fontFamily: "Manrope", ml: 1 }}
                    >
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                    <TextField
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                      variant="outlined"
                      placeholder="Enter Email Id"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                              fontSize: 20,
                              backgroundColor: "#DDDDDB",
                              mr: -1.2,
                              py: 2,
                              px: 1,
                              borderRadius: 6,
                            }}
                          >
                            <Button
                              type="submit"
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Manrope",
                                color: "#000000",
                                textTransform: "none",
                              }}
                            >
                              Subscribe
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Manrope",
                                  color: "#000000",
                                  backgroundColor: "#ffffff",
                                  p: 0.3,
                                  px: 0.8,
                                  borderRadius: "50%",
                                  mr: -1.7,
                                  ml: 2,
                                  mt: 0.1,
                                }}
                              >
                                <PiArrowDownRightBold />
                              </Typography>
                            </Button>
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "30px",
                          width: "330px",
                          height: "40px",
                          display: { sm: "none", md: "flex" },
                        },
                      }}
                      inputProps={{
                        sx: {
                          fontFamily: "Manrope",
                          fontWeight: 600,
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          fontFamily: "Manrope",
                        },
                      }}
                    />
                  </FormControl>
                </form>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                mt: 6,
              }}
            >
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "24px", fontWeight: 400 }}
              >
                Our
              </Typography>
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{
                  fontSize: "24px",
                  fontWeight: 400,
                  fontStyle: "italic",
                }}
              >
                Blogs
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            marginTop="30px"
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <img src={blog1} alt="" width="100%" />
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "10px", fontWeight: 600, mt: 1 }}
              >
                Msigma Group | 03 June 2024
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "14px", fontWeight: 600, mt: 1 }}
              >
                Unlock Your Potential: Mastering the GATE Exam
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{ fontSize: "12px", fontWeight: 400, mt: 1 }}
              >
                Are you ready to pave your way to success? Dive into our
                comprehensive blog series designed to guide you through the
                intricacies of the GATE exam.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                width: "100%",
                display: "flex",
                mt: 2,
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",

                  width: "50%",
                }}
              >
                <Box>
                  <img src={blog2} alt="" width="160px" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "10px", fontWeight: 400 }}
                  >
                    Msigma Group | 03 June 2024
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                  >
                    Unlock Your Potential: Mastering the GATE Exam
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  justifyContent: "start",

                  width: "50%",
                }}
              >
                <Box>
                  <img src={blog3} alt="" width="160px" />
                </Box>
                <Box>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "10px", fontWeight: 400 }}
                  >
                    Msigma Group | 03 June 2024
                  </Typography>
                  <Typography
                    fontFamily="Manrope"
                    sx={{ fontSize: "12px", fontWeight: 600 }}
                  >
                    Unlock Your Potential: Mastering the GATE Exam
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleButtonClick}
              fontFamily="Manrope"
              style={{
                backgroundColor: "#ffffff",
              }}
              sx={{
                borderRadius: 15,
                py: 1.5,
                color: "#000000",
                textAlign: "center",
                px: 2,
                fontSize: "16px",
                textTransform: "none",
                mt: 4,
                fontWeight: 500,
                width: "162px",
              }}
            >
              View All
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Blog;
