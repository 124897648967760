import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import style from "./ZiData.module.scss";

const ZiBanner = () => {
  return (
    <Box>
      <Box className={style.deskTopWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
              py: 10,
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 20,
                
              }}
             >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                 
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "45px",
                    fontWeight: "500",
                    mt:-30
                  }}
                >
                  Zero to Infinity
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "45px",
                    fontWeight: "300",
                   
                  }}
                >
                  Empowering Tomorrow’s Engineers
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "15px",
                  fontWeight: "400",
                  mt: 33,
                  textAlign:'center'
                }}
              >
                Zero to Infinity is an all-inclusive four-year programme,
                specially designed for first-year engineering students at KTU.
                Strategised by experts in the field, the programme assists
                students in their B.Tech classes and aids in building skills
                that will help them in their careers. Zero to Infinity consists
                of a plethora of features that ensure that students joining the
                course possess all they require for the four years and cement
                their careers
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={style.tabletWrapper}>
        <Box>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                p: 6,
              }}
             >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
               >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "35px",
                    fontWeight: "500",
                  }}
                >
                  Zero to Infinity
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "35px",
                    fontWeight: "300",
                    mt: -1,
                  }}
                >
                  Empowering Tomorrow’s Engineers
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "13px",
                  fontWeight: "400",
                  mt: 23,
                  textAlign:'center',
                }}
              >
                Zero to Infinity is an all-inclusive four-year programme,
                specially designed for first-year engineering students at KTU.
                Strategised by experts in the field, the programme assists
                students in their B.Tech classes and aids in building skills
                that will help them in their careers. Zero to Infinity consists
                of a plethora of features that ensure that students joining the
                course possess all they require for the four years and cement
                their careers
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className={style.responsiveWrapper}>
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70vh",
            px: 2,
          }}
        >
          <Box>
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
              }}
            >
              <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                
              }}
             >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt:-5
                }}
               >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    color: "#ffffff",
                    fontSize: "25px",
                    fontWeight: "500",
                  }}
                >
                  Zero to Infinity
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    color: "#ffffff",
                    fontSize: "25px",
                    fontWeight: "300",
                    textAlign:'center',
                    
                  }}
                >
                  Empowering Tomorrow’s Engineers
                </Typography>
              </Box>
              <Typography
                fontFamily="Manrope"
                sx={{
                  color: "#ffffff",
                  fontSize: "13px",
                  fontWeight: "400",
                  mt: 32,
                  textAlign:'center',
                }}
              >
                Zero to Infinity is an all-inclusive four-year programme,
                specially designed for first-year engineering students at KTU.
                Strategised by experts in the field, the programme assists
                students in their B.Tech classes and aids in building skills
                that will help them in their careers. Zero to Infinity consists
                of a plethora of features that ensure that students joining the
                course possess all they require for the four years and cement
                their careers
              </Typography>
            </Grid>
            </Grid>
          </Box>
        </Grid>
      </div>
    </Box>
  );
};

export default ZiBanner;
