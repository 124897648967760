import React, { useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { LuSearch } from "react-icons/lu";
import bl1 from "../../assets/images/bll1.png";
import oneRupee from "../../assets/images/blog/oneRupee.png";
import { featuredPost, latestPost } from "./Blog.content";
import { useFormik } from "formik";
import * as Yup from "yup";

const formSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email ID Required"),
});

const BlogDesktop = () => {
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = React.useState("All Blogs");
  const [searchQuery, setSearchQuery] = useState("");

  const getFilteredBlogs = () => {
    const blogsByCategory =
      activeButton === "All Blogs"
        ? latestPost
        : latestPost.filter((blog) => blog.category === activeButton);

    if (searchQuery) {
      return blogsByCategory.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return blogsByCategory.slice(0, 3);
  };

  // Filter the posts based on the selected category
  const filteredBlogs = getFilteredBlogs();

  const getButtonStyle = (buttonName) => ({
    fontSize: { xs: "14px", md: "16px" },
    textTransform: "none",
    fontFamily: "Manrope",
    fontWeight: 600,
    color: "black",
    borderBottom: activeButton === buttonName ? "2px solid #3878F2" : "none",
    borderRadius: "0",
  });

  const handleClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update search query
  };

  const threeFeatured = featuredPost.slice(0, 3);

  const clampedStyle = {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
  };

  // formik
  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const formData = {
        email: values?.email,
      };

      const status = formData;
      if (status) {
        setLoading(false);
        alert("Submitted, we will contact you");

        resetForm({
          values: {
            email: "",
          },
        });
      }
    },
    validationSchema: formSchema,
  });

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container sx={{ width: "100%", px: 8 }}>
            <Grid
              item
              sx={{
                width: "100%",
                borderBottom: "2px solid #DBDBDB",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: { xs: "center", sm: "space-between" },
              }}
            >
              <ButtonGroup sx={{ mb: -2.5 }}>
                <Button
                  variant="text"
                  sx={getButtonStyle("All Blogs")}
                  onClick={() => handleClick("All Blogs")}
                >
                  All Blogs
                </Button>
                <Button
                  variant="text"
                  sx={getButtonStyle("Engineering")}
                  onClick={() => handleClick("Engineering")}
                >
                  Engineering
                </Button>
                <Button
                  variant="text"
                  sx={getButtonStyle("ISRO")}
                  onClick={() => handleClick("ISRO")}
                >
                  ISRO
                </Button>
                <Button
                  variant="text"
                  sx={getButtonStyle("UPSC")}
                  onClick={() => handleClick("UPSC")}
                >
                  UPSC
                </Button>
              </ButtonGroup>

              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LuSearch />
                <TextField
                  value={searchQuery}
                  onChange={handleSearchChange}
                  id="standard-basic"
                  label="Search..."
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& label": {
                      fontFamily: "Manrope",
                      fontWeight: 500,
                      color: "#848484",
                    },
                    "& input": {
                      fontFamily: "Manrope",
                      fontWeight: 500,
                    },
                    mb: 1.5,
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container sx={{ width: "100%", px: 8, mt: 6 }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "100%",
                  md: "70%",
                },
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    fontFamily="Manrope"
                    sx={{
                      fontSize: { xs: "20px", md: "30px" },
                      fontWeight: 400,
                    }}
                  >
                    {activeButton === "All Blogs" ? "Latest" : ""}
                  </Typography>
                  <Typography
                    fontFamily="Tiempos Text Medium Italic"
                    sx={{
                      fontSize: { xs: "20px", md: "30px" },
                      fontWeight: 400,
                      fontStyle: "italic",
                    }}
                  >
                    {activeButton === "All Blogs" ? "Stories" : activeButton}
                  </Typography>
                </Box>

                <Button
                  variant="text"
                  sx={{
                    textTransform: "none",
                    fontSize: { xs: "12px", md: "16px" },
                    fontFamily: "Manrope",
                    color: "#000000",
                  }}
                >
                  View All
                </Button>
              </Box>

              {activeButton === "All Blogs" && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                      mb: 4,
                    }}
                   >
                    <img src={bl1} alt="" width="100%" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "12px", md: "15px" },
                        fontWeight: 400,
                        mt: 0.5,
                      }}
                    >
                      Msigma Group | 03 June 2024
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "16px", md: "28px" },
                        fontWeight: 600,
                        my: 0.5,
                      }}
                    >
                      Unlock Your Potential: Mastering the GATE Exam
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "12px", md: "15px" },
                        fontWeight: 500,
                      }}
                    >
                      Are you ready to pave your way to success? Dive into our
                      comprehensive blog series designed to guide you through
                      the intricacies of the GATE exam. Dive into our
                      comprehensive blog series designed to guide you through
                      the intricacies of the GATE exam.{" "}
                    </Typography>
                  </Box>
                </>
              )}

              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: {xs:'center', sm:"start"},
                  gap:{ xs:1,sm:2},
                  mb: 4,
                  borderBottom: "2px solid #DBDBDB",
                  pb: {xs:3,md:6},
                  flexWrap: "wrap", // Wrap the content
                }}
              >
                {filteredBlogs.map((latest, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: {xs:'45%', sm:"32%"},
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "start",
                      mb: 3,
                    }}
                  >
                    <img src={latest.thumbNail} alt="" width="100%" />
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "12px", md: "14px" },
                        fontWeight: 400,
                        mt: 0.5,
                      }}
                    >
                      {latest.date}
                    </Typography>
                    <Typography
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "13px", md: "16px" },
                        fontWeight: 600,
                        my: 0.5,
                      }}
                    >
                      {latest.title}
                    </Typography>
                    <Typography
                      style={clampedStyle}
                      fontFamily="Manrope"
                      sx={{
                        fontSize: { xs: "11px", md: "12px" },
                        fontWeight: 500,
                      }}
                    >
                      {latest.description}
                    </Typography>
                  </Box>
                ))}
              </Grid>

              {/* Featured stories section visible only when "All Blogs" is selected */}
              {activeButton === "All Blogs" && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        fontFamily="Manrope"
                        sx={{
                          fontSize: { xs: "20px", md: "30px" },
                          fontWeight: 400,
                        }}
                      >
                        Featured
                      </Typography>
                      <Typography
                        fontFamily="Tiempos Text Medium Italic"
                        sx={{
                          fontSize: { xs: "20px", md: "30px" },
                          fontWeight: 400,
                          fontStyle: "italic",
                        }}
                      >
                        Stories
                      </Typography>
                    </Box>

                    <Button
                      variant="text"
                      sx={{
                        textTransform: "none",
                        fontSize: { xs: "12px", md: "16px" },
                        fontFamily: "Manrope",
                        color: "#000000",
                      }}
                    >
                      View All
                    </Button>
                  </Box>

                  <Grid
                    item
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    {featuredPost.map((featured, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "32%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          justifyContent: "start",
                          mb: 3,
                        }}
                      >
                        <img src={featured.thumbNail} alt="" width="100%" />
                        <Typography
                          fontFamily="Manrope"
                          sx={{
                            fontSize: { xs: "12px", md: "14px" },
                            fontWeight: 400,
                            mt: 0.5,
                          }}
                        >
                          {featured.date}
                        </Typography>
                        <Typography
                          fontFamily="Manrope"
                          sx={{
                            fontSize: { xs: "13px", md: "16px" },
                            fontWeight: 600,
                            my: 0.5,
                          }}
                        >
                          {featured.title}
                        </Typography>
                        <Typography
                          style={clampedStyle}
                          fontFamily="Manrope"
                          sx={{
                            fontSize: { xs: "11px", md: "12px" },
                            fontWeight: 500,
                          }}
                        >
                          {featured.description}
                        </Typography>
                      </Box>
                    ))}
                  </Grid>
                </>
              )}
            </Grid>

            {/* Right-hand 30% grid remains the same */}
            <Grid item sx={{ width: "30%", pl: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#43BA80",
                  p: 3,
                  width: "100%",
                  mt: 6,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: "20px", md: "30px", color: "#ffffff" },
                    fontWeight: 600,
                    textAlign: "start",
                  }}
                >
                  MSigma Gokulam Online Newsletter
                </Typography>
                <Typography
                  fontFamily="Manrope"
                  sx={{
                    fontSize: { xs: "12px", md: "14px", color: "#ffffff" },
                    fontWeight: 400,
                    textAlign: "start",
                  }}
                >
                  Subscribe to our newsletter for the latest updates delivered
                  directly to your inbox.
                </Typography>

                <form onSubmit={formik.handleSubmit}>
                  <FormControl
                    sx={{
                      display: {
                        sm: "none",
                        md: "flex",
                        flexDirection: "column",
                        width: "100%",
                      },
                    }}
                  >
                    <Typography
                      sx={{ color: "#fc0303", fontFamily: "Manrope" }}
                    >
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                    <TextField
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                      label="Your email address"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        "& label": {
                          fontFamily: "Manrope",
                          fontWeight: 500,
                          color: "#848484",
                        },
                        "& label.Mui-focused": {
                          color: "#ffffff",
                        },
                        "& label.MuiFormLabel-filled": {
                          color: "#ffffff",
                        },
                        "& input": {
                          fontFamily: "Manrope",
                          fontWeight: 500,
                        },
                        mt: 0.5,
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        width: "100%",
                        maxWidth: "700px",
                        minWidth: "330px",
                        fontFamily: "Manrope",
                        textTransform: "none",
                        backgroundColor: "#3878F2",
                        color: "#FFFFFF",
                        mt: 2,
                        boxShadow: "none",
                        py: 2,
                        borderRadius: 0,
                      }}
                    >
                      Subscribe
                    </Button>
                  </FormControl>
                </form>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  mt: 3,
                }}
              >
                <Typography
                  fontFamily="Manrope"
                  sx={{ fontSize: { xs: "16px", md: "20px" }, fontWeight: 400 }}
                >
                  Featured
                </Typography>
                <Typography
                  fontFamily="Tiempos Text Medium Italic"
                  sx={{
                    fontSize: { xs: "16px", md: "20px" },
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  Posts
                </Typography>
              </Box>
              <Grid
                item
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                {threeFeatured.map((three, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      mb: 3,
                      gap: 1,
                    }}
                  >
                    <Box sx={{ width: "39%" }}>
                      <img
                        src={three.thumbNail}
                        alt=""
                        width="100%"
                        height="100px"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "59%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                      }}
                    >
                      <Typography
                        fontFamily="Manrope"
                        sx={{
                          fontSize: { xs: "11px", md: "13px" },
                          fontWeight: 400,
                        }}
                      >
                        {three.date}
                      </Typography>

                      <Typography
                        fontFamily="Manrope"
                        sx={{
                          fontSize: { xs: "12px", md: "13px" },
                          fontWeight: 600,
                          my: 0.5,
                        }}
                      >
                        {three.title}
                      </Typography>

                      <Typography
                        style={clampedStyle}
                        fontFamily="Manrope"
                        sx={{
                          fontSize: { xs: "10px", md: "11px" },
                          fontWeight: 500,
                        }}
                      >
                        {three.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Grid>

              <Box sx={{ mt: 3 }}>
                <img src={oneRupee} alt="" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default BlogDesktop;

// import React, { useState } from "react";
// import Box from "@mui/material/Box";
// import {
//   Button,
//   ButtonGroup,
//   FormControl,
//   Grid,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { LuSearch } from "react-icons/lu";
// import bl1 from "../../assets/images/bll1.png";
// import oneRupee from "../../assets/images/blog/oneRupee.png";
// import { featuredPost, latestPost } from "./Blog.content";
// import { useFormik } from "formik";
// import * as Yup from "yup";

// const formSchema = Yup.object({
//   email: Yup.string().email("Invalid email").required("Email ID Required"),
// });

// const BlogDesktop = () => {
//   const [loading, setLoading] = useState(false);
//   const [activeButton, setActiveButton] = React.useState("All Blogs");
//   const [searchQuery, setSearchQuery] = useState("");

//   const getFilteredBlogs = () => {
//     const blogsByCategory =
//       activeButton === "All Blogs"
//         ? latestPost
//         : latestPost.filter((blog) => blog.category === activeButton);

//     if (searchQuery) {
//       return blogsByCategory.filter((blog) =>
//         blog.title.toLowerCase().includes(searchQuery.toLowerCase())
//       );
//     }

//     return blogsByCategory.slice(0, 3);
//   };

//   // Filter the posts based on the selected category
//   const filteredBlogs = getFilteredBlogs();

//   const getButtonStyle = (buttonName) => ({
//     fontSize: { xs: "14px", md: "16px" },
//     textTransform: "none",
//     fontFamily: "Manrope",
//     fontWeight: 600,
//     color: "black",
//     borderBottom: activeButton === buttonName ? "2px solid #3878F2" : "none",
//     borderRadius: "0",
//   });

//   const handleClick = (buttonName) => {
//     setActiveButton(buttonName);
//   };

//   const handleSearchChange = (event) => {
//     setSearchQuery(event.target.value); // Update search query
//   };

//   const threeFeatured = featuredPost.slice(0, 3);

//   const clampedStyle = {
//     display: "-webkit-box",
//     WebkitBoxOrient: "vertical",
//     overflow: "hidden",
//     WebkitLineClamp: 2,
//   };

//   // formik
//   const formik = useFormik({
//     initialValues: {
//       fullName: "",
//       phoneNumber: "",
//       email: "",
//     },
//     onSubmit: async (values, { resetForm }) => {
//       setLoading(true);
//       const formData = {
//         email: values?.email,
//       };

//       const status = formData;
//       if (status) {
//         setLoading(false);
//         alert("Submitted, we will contact you");

//         resetForm({
//           values: {
//             email: "",
//           },
//         });
//       }
//     },
//     validationSchema: formSchema,
//   });

//   return (
//     <>
//       <Grid container sx={{ width: "100%", px: 8 }}>
//         <Grid
//           item
//           sx={{
//             width: "100%",
//             borderBottom: "2px solid #DBDBDB",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           <ButtonGroup sx={{ mb: -2.5 }}>
//             <Button
//               variant="text"
//               sx={getButtonStyle("All Blogs")}
//               onClick={() => handleClick("All Blogs")}
//             >
//               All Blogs
//             </Button>
//             <Button
//               variant="text"
//               sx={getButtonStyle("Engineering")}
//               onClick={() => handleClick("Engineering")}
//             >
//               Engineering
//             </Button>
//             <Button
//               variant="text"
//               sx={getButtonStyle("ISRO")}
//               onClick={() => handleClick("ISRO")}
//             >
//               ISRO
//             </Button>
//             <Button
//               variant="text"
//               sx={getButtonStyle("UPSC")}
//               onClick={() => handleClick("UPSC")}
//             >
//               UPSC
//             </Button>
//           </ButtonGroup>

//           <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//             <LuSearch />
//             <TextField
//               value={searchQuery}
//               onChange={handleSearchChange}
//               id="standard-basic"
//               label="Search..."
//               variant="standard"
//               sx={{
//                 "& .MuiInput-underline:before": {
//                   borderBottom: "none",
//                 },
//                 "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
//                   borderBottom: "none",
//                 },
//                 "& .MuiInput-underline:after": {
//                   borderBottom: "none",
//                 },
//                 "& label": {
//                   fontFamily: "Manrope",
//                   fontWeight: 500,
//                   color: "#848484",
//                 },
//                 "& input": {
//                   fontFamily: "Manrope",
//                   fontWeight: 500,
//                 },
//                 mb: 1.5,
//               }}
//             />
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container sx={{ width: "100%", px: 8, mt: 6 }}>
//         <Grid item sx={{ width: "70%" }}>
//           <Box
//             sx={{
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <Typography
//                 fontFamily="Manrope"
//                 sx={{ fontSize: { xs: "20px", md: "30px" }, fontWeight: 400 }}
//               >
//                 {activeButton === "All Blogs" ? "Latest" : ""}
//               </Typography>
//               <Typography
//                 fontFamily="Tiempos Text Medium Italic"
//                 sx={{
//                   fontSize: { xs: "20px", md: "30px" },
//                   fontWeight: 400,
//                   fontStyle: "italic",
//                 }}
//               >
//                 {activeButton === "All Blogs" ? "Stories" : activeButton}
//               </Typography>
//             </Box>

//             <Button
//               variant="text"
//               sx={{
//                 textTransform: "none",
//                 fontSize: { xs: "12px", md: "16px" },
//                 fontFamily: "Manrope",
//                 color: "#000000",
//               }}
//             >
//               View All
//             </Button>
//           </Box>

//           {activeButton === "All Blogs" && (
//             <>
//               <Box
//                 sx={{
//                   width: "100%",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "start",
//                   justifyContent: "start",
//                   mb: 4,
//                 }}
//               >
//                 <img src={bl1} alt="" width="100%" />
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: "12px", md: "15px" },
//                     fontWeight: 400,
//                     mt: 0.5,
//                   }}
//                 >
//                   Msigma Group | 03 June 2024
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: "16px", md: "28px" },
//                     fontWeight: 600,
//                     my: 0.5,
//                   }}
//                 >
//                   Unlock Your Potential: Mastering the GATE Exam
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{ fontSize: { xs: "12px", md: "15px" }, fontWeight: 500 }}
//                 >
//                   Are you ready to pave your way to success? Dive into our
//                   comprehensive blog series designed to guide you through the
//                   intricacies of the GATE exam. Dive into our comprehensive blog
//                   series designed to guide you through the intricacies of the
//                   GATE exam.{" "}
//                 </Typography>
//               </Box>
//             </>
//           )}

//           <Grid
//             item
//             sx={{
//               width: "100%",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "start",
//               gap: 2,
//               mb: 4,
//               borderBottom: "2px solid #DBDBDB",
//               pb: 6,
//               flexWrap: "wrap", // Wrap the content
//             }}
//           >
//             {filteredBlogs.map((latest, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   width: "32%",
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "start",
//                   justifyContent: "start",
//                   mb: 3,
//                 }}
//               >
//                 <img src={latest.thumbNail} alt="" width="100%" />
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: "12px", md: "14px" },
//                     fontWeight: 400,
//                     mt: 0.5,
//                   }}
//                 >
//                   {latest.date}
//                 </Typography>
//                 <Typography
//                   fontFamily="Manrope"
//                   sx={{
//                     fontSize: { xs: "13px", md: "16px" },
//                     fontWeight: 600,
//                     my: 0.5,
//                   }}
//                 >
//                   {latest.title}
//                 </Typography>
//                 <Typography
//                   style={clampedStyle}
//                   fontFamily="Manrope"
//                   sx={{ fontSize: { xs: "11px", md: "12px" }, fontWeight: 500 }}
//                 >
//                   {latest.description}
//                 </Typography>
//               </Box>
//             ))}
//           </Grid>

//           {/* Featured stories section visible only when "All Blogs" is selected */}
//           {activeButton === "All Blogs" && (
//             <>
//               <Box
//                 sx={{
//                   width: "100%",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: "20px", md: "30px" },
//                       fontWeight: 400,
//                     }}
//                   >
//                     Featured
//                   </Typography>
//                   <Typography
//                     fontFamily="Tiempos Text Medium Italic"
//                     sx={{
//                       fontSize: { xs: "20px", md: "30px" },
//                       fontWeight: 400,
//                       fontStyle: "italic",
//                     }}
//                   >
//                     Stories
//                   </Typography>
//                 </Box>

//                 <Button
//                   variant="text"
//                   sx={{
//                     textTransform: "none",
//                     fontSize: { xs: "12px", md: "16px" },
//                     fontFamily: "Manrope",
//                     color: "#000000",
//                   }}
//                 >
//                   View All
//                 </Button>
//               </Box>

//               <Grid
//                 item
//                 sx={{
//                   width: "100%",
//                   display: "flex",
//                   flexWrap: "wrap",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   mt: 1,
//                 }}
//               >
//                 {featuredPost.map((featured, index) => (
//                   <Box
//                     key={index}
//                     sx={{
//                       width: "32%",
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "start",
//                       justifyContent: "start",
//                       mb: 3,
//                     }}
//                   >
//                     <img src={featured.thumbNail} alt="" width="100%" />
//                     <Typography
//                       fontFamily="Manrope"
//                       sx={{
//                         fontSize: { xs: "12px", md: "14px" },
//                         fontWeight: 400,
//                         mt: 0.5,
//                       }}
//                     >
//                       {featured.date}
//                     </Typography>
//                     <Typography
//                       fontFamily="Manrope"
//                       sx={{
//                         fontSize: { xs: "13px", md: "16px" },
//                         fontWeight: 600,
//                         my: 0.5,
//                       }}
//                     >
//                       {featured.title}
//                     </Typography>
//                     <Typography
//                       style={clampedStyle}
//                       fontFamily="Manrope"
//                       sx={{
//                         fontSize: { xs: "11px", md: "12px" },
//                         fontWeight: 500,
//                       }}
//                     >
//                       {featured.description}
//                     </Typography>
//                   </Box>
//                 ))}
//               </Grid>
//             </>
//           )}
//         </Grid>

//         {/* Right-hand 30% grid remains the same */}
//         <Grid item sx={{ width: "30%", pl: 4 }}>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//               backgroundColor: "#43BA80",
//               p: 3,
//               width: "100%",
//               mt: 6,
//             }}
//           >
//             <Typography
//               fontFamily="Manrope"
//               sx={{
//                 fontSize: { xs: "20px", md: "30px", color: "#ffffff" },
//                 fontWeight: 600,
//                 textAlign: "start",
//               }}
//             >
//               MSigma Gokulam Online Newsletter
//             </Typography>
//             <Typography
//               fontFamily="Manrope"
//               sx={{
//                 fontSize: { xs: "12px", md: "14px", color: "#ffffff" },
//                 fontWeight: 400,
//                 textAlign: "start",
//               }}
//             >
//               Subscribe to our newsletter for the latest updates delivered
//               directly to your inbox.
//             </Typography>

//             <form onSubmit={formik.handleSubmit}>
//               <FormControl
//                 sx={{
//                   display: {
//                     sm: "none",
//                     md: "flex",
//                     flexDirection: "column",
//                     width: "100%",
//                   },
//                 }}
//               >
//                 <Typography sx={{ color: "#fc0303", fontFamily: "Manrope" }}>
//                   {formik.touched.email && formik.errors.email}
//                 </Typography>
//                 <TextField
//                   value={formik.values.email}
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   id="email"
//                   name="email"
//                   label="Your email address"
//                   variant="outlined"
//                   sx={{
//                     width: "100%",
//                     backgroundColor: "#FFFFFF",
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         border: "none",
//                       },
//                       "&:hover fieldset": {
//                         border: "none",
//                       },
//                       "&.Mui-focused fieldset": {
//                         border: "none",
//                       },
//                     },
//                     "& label": {
//                       fontFamily: "Manrope",
//                       fontWeight: 500,
//                       color: "#848484",
//                     },
//                     "& label.Mui-focused": {
//                       color: "#ffffff",
//                     },
//                     "& label.MuiFormLabel-filled": {
//                       color: "#ffffff",
//                     },
//                     "& input": {
//                       fontFamily: "Manrope",
//                       fontWeight: 500,
//                     },
//                     mt: 0.5,
//                   }}
//                 />
//                 <Button
//                   type="submit"
//                   variant="contained"
//                   sx={{
//                     width: "100%",
//                     maxWidth: "700px",
//                     minWidth: "330px",
//                     fontFamily: "Manrope",
//                     textTransform: "none",
//                     backgroundColor: "#3878F2",
//                     color: "#FFFFFF",
//                     mt: 2,
//                     boxShadow: "none",
//                     py: 2,
//                     borderRadius: 0,
//                   }}
//                 >
//                   Subscribe
//                 </Button>
//               </FormControl>
//             </form>
//           </Box>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "start",
//               mt: 3,
//             }}
//           >
//             <Typography
//               fontFamily="Manrope"
//               sx={{ fontSize: { xs: "16px", md: "20px" }, fontWeight: 400 }}
//             >
//               Featured
//             </Typography>
//             <Typography
//               fontFamily="Tiempos Text Medium Italic"
//               sx={{
//                 fontSize: { xs: "16px", md: "20px" },
//                 fontWeight: 400,
//                 fontStyle: "italic",
//               }}
//             >
//               Posts
//             </Typography>
//           </Box>
//           <Grid
//             item
//             sx={{
//               width: "100%",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//               mt: 1,
//             }}
//           >
//             {threeFeatured.map((three, index) => (
//               <Box
//                 key={index}
//                 sx={{
//                   width: "100%",
//                   display: "flex",
//                   alignItems: "start",
//                   justifyContent: "start",
//                   mb: 3,
//                   gap: 1,
//                 }}
//               >
//                 <Box sx={{ width: "39%" }}>
//                   <img
//                     src={three.thumbNail}
//                     alt=""
//                     width="100%"
//                     height="100px"
//                   />
//                 </Box>
//                 <Box
//                   sx={{
//                     width: "59%",
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "start",
//                     justifyContent: "start",
//                   }}
//                 >
//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: "11px", md: "13px" },
//                       fontWeight: 400,
//                     }}
//                   >
//                     {three.date}
//                   </Typography>

//                   <Typography
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: "12px", md: "13px" },
//                       fontWeight: 600,
//                       my: 0.5,
//                     }}
//                   >
//                     {three.title}
//                   </Typography>

//                   <Typography
//                     style={clampedStyle}
//                     fontFamily="Manrope"
//                     sx={{
//                       fontSize: { xs: "10px", md: "11px" },
//                       fontWeight: 500,
//                     }}
//                   >
//                     {three.description}
//                   </Typography>
//                 </Box>
//               </Box>
//             ))}
//           </Grid>

//           <Box sx={{ mt: 3 }}>
//             <img src={oneRupee} alt="" width="100%" />
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default BlogDesktop;
