import { Box, Grid, Typography, List, ListItem } from "@mui/material";
import React from "react";
import { ziData } from "./ZiData.content";
import app from "../../../assets/logo/appW.png";
import play from "../../../assets/logo/playW.png";
import zero from "../../../assets/images/zinf.png";

const textStyle = {
  fontSize: { xs: "12px", sm: "13px", md: "15px" },
  fontWeight: 500,
  textAlign: "justify",
  color: "#FFFFFF",
  mt: 2,
  fontFamily: "Manrope",
};

const ZiData = () => {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        py: 4,
        px: { xs: 4, sm: 4, md: 6, lg: 8 },
        backgroundColor: "#000000",
        display: "flex",
        alignItems: "start",
        justifyContent: {xs:'end',md:"center"},
      }}
    >
      {ziData.map((data, index) => (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{
            width: { xs: "100%", md: "48%" },
            display: "flex",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: { xs: "center", md: "space-between" },
              gap: { xs: 1 },
            }}
          >
            <Typography
              fontFamily="Manrope"
              sx={{
                fontSize: { xs: "5rem", sm: "7rem", md: "8rem" },
                fontWeight: 200,
                textAlign: "justify",
                background: "linear-gradient(-90deg, #FFFFFF, #010101)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {data.slNO}
            </Typography>
            <Box
              sx={{
                width: "560px",
                backgroundColor: "#ffffff",
                p: 3,
                height: { md: "280px" },
              }}
            >
              <Typography
                fontFamily="Tiempos Text Medium Italic"
                sx={{
                  fontSize: { xs: "24px", sm: "26px", md: "28px" },
                  fontWeight: 500,
                  textAlign: "start",
                }}
              >
                {data.title}
              </Typography>
              <Typography
                fontFamily="Manrope"
                sx={{
                  fontSize: { xs: "13px", md: "14px" },
                  fontWeight: 500,
                  lineHeight: { xs: "24px", sm: "27px", md: "30px" },
                  textAlign: "justify",
                }}
              >
                {data.description}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "100%", md: "48%" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "15px", sm: "18px", md: "24px" },
              fontWeight: 500,
              textAlign: "justify",
              color: "#FFFFFF",
            }}
          >
            Education for Dynamic Growth and Employability (EDGE)
          </Typography>
          <Typography
            fontFamily="Manrope"
            sx={{
              fontSize: { xs: "12px", sm: "13px", md: "14px" },
              fontWeight: 500,
              lineHeight: { xs: "18px", sm: "20px", md: "25px" },
              textAlign: "justify",
              color: "#FFFFFF",
            }}
          >
            The <span style={{ color: "#00FFD2" }}>EDGE Programme</span> by
            <span style={{ color: "#00FFD2" }}> Msigma Gokulam </span>is part of
            Zero to Infinity, which contributes to the career development of the
            students through skill development.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", width: "100%",flexDirection:{xs:'column',sm: "row"}, }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: {xs:'100%',sm:"55%"} }}>
            <Typography sx={textStyle}>
              It includes but is not limited to:
            </Typography>
            <List>
              {[
                "Collaboration with Foreign Universities",
                "Open Source Contribution",
                "Collaboration with industry and NGOs",
                "Hands-on project simulations based on industry standards (Assists in publishing papers and obtaining patents)",
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                    paddingLeft: 0,
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#00FFD2",
                      fontSize: { xs: "12px", sm: "13px", md: "14px" },
                    }}
                  >
                    ●
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "13px", md: "14px" },
                      fontWeight: 400,
                      textAlign: "justify",
                      color: "#FFFFFF",
                      fontFamily: "Manrope",
                    }}
                  >
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "13px", md: "14px" },
                fontWeight: 400,
                textAlign: "justify",
                color: "#00FFD2",
                fontFamily: "Manrope",
                p: 0,
                m: 1,
              }}
            >
              We offer professional help to follow your passion and develop the
              required skills to get placed in reputed organisations with
              attractive packages.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "13px", md: "14px" },
                fontWeight: 500,
                textAlign: "justify",
                color: "#FFFFFF",
                fontFamily: "Manrope",
                mt: 1,
              }}
            >
              Download the Msigma Gokulam mobile app and join Zero to Infinity
              to reshape your engineering career
            </Typography>
            <Box sx={{ width: "100%", display: "flex", gap: 2, mt: 1 }}>
              <img src={app} alt="IMG1" width="120px" />
              <img src={play} alt="IMG2" width="120px" />
            </Box>
          </Box>
          <Box sx={{ width:{ xs:'100%',sm:"45%"} }}>
            <img src={zero} alt="IMG" width="365px" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ZiData;
