import ZiBanner from 'components/ZeroToInfinity/ZIBanner/ZiBanner'
import ZiData from 'components/ZeroToInfinity/ZiData/ZiData'
import React from 'react'

const ZeroToInfinity = () => {
  return (
    <div>
      <ZiBanner/>
      <ZiData/>
    </div>
  )
}

export default ZeroToInfinity
