import manu from "../../../assets/images/profile/manu.png";
import ranga from "../../../assets/images/profile/ranga.png";
import shekhar from "../../../assets/images/profile/shekhar.png";
import charlies from "../../../assets/images/profile/Charlies.png";
import rajarao from "../../../assets/images/profile/rajarao.png";
import vsr from "../../../assets/images/profile/vsr.png";
import trinath from "../../../assets/images/profile/trinath.png";
import nithin from "../../../assets/images/profile/Nithin Sir.png";
import bala from "../../../assets/images/profile/bala.png";
import vaka from "../../../assets/images/profile/vaka.png";
import prasanna from "../../../assets/images/profile/Prasanna.png";
import naveen from "../../../assets/images/profile/naveen.png";
import vinod from "../../../assets/images/profile/vinod.png";
import vishnu from "../../../assets/images/profile/vishnu.png";
import dipin from "../../../assets/images/profile/dipin.png";
import arjun from "../../../assets/images/profile/Arjun.png";
import athira from "../../../assets/images/profile/Athira.png";
import renjan from "../../../assets/images/profile/Renjen.png";
import akhila from "../../../assets/images/profile/akhila.png";
import prasanth from "../../../assets/images/profile/prasanth.png";
import murali from "../../../assets/images/profile/murali.png";
import s_yadhav from "../../../assets/images/profile/Surendra Yadav.png";
import rangaReddy from "../../../assets/images/profile/rangaReddy.png";
import hari from "../../../assets/images/profile/hari.png";
import mojya from "../../../assets/images/profile/mojya.png";
import abhijin from "../../../assets/images/profile/abhijin.png";
import arya from "../../../assets/images/profile/Arya.png";
import afsal from "../../../assets/images/profile/afsal.png";
import padmaja from "../../../assets/images/profile/padmaja.png";
import betsy from "../../../assets/images/profile/betsy.png";
import radhika from "../../../assets/images/profile/radhika.png";
import anju from "../../../assets/images/profile/anju.png";
import Roshan from "../../../assets/images/profile/Roshan.png";
import anoop from "../../../assets/images/profile/anoop.png";

import rnd from "../../../assets/images/rnd.png";
import satelite from "../../../assets/images/satelite.png";
import acad from "../../../assets/images/acad.png";
import oxdn from "../../../assets/images/oxdn.webp";
import reservoir from "../../../assets/images/reservoir.webp";
import elctro from "../../../assets/images/Electromagnetics.webp";
import powerElectrical from "../../../assets/images/Power Electricals.webp";
import measure from "../../../assets/images/measure.webp";
import networkTheory from "../../../assets/images/Network Theory.webp";
import powerSystem from "../../../assets/images/Power System.webp";
import controlSystem from "../../../assets/images/Control System.webp";
import transducers from "../../../assets/images/Transducers.webp";

export const facultyDetails = [
  // 1
  {
    id: 1,
    name: "Manu M Rajan",
    designation: "Director and CEO",
    profilePhoto: manu,
    aboutMe:
      'Manu M Rajan, Director, and CEO of Msigma Gokulam, is a visionary leader focused on making technological education engaging, results-driven, and accessible. He is committed to shaping the future of India’s engineering students by implementing innovative learning strategies and creating a pathway for academic and career success.',
    academics: [
      {
        qualifications: "B.Tech in Electrical and Electronics Engineering",
        institute: "Government Engineering College, Kannur",
      },
    ],
    experience: [
      {
        company: "Prithvi Information Solutions",
        position: "RF Engineer",
        details:
          "Before starting his career at Msigma, Manu M Rajan was an RF Engineer at Prithvi Information Solutions which helped him mould his career. It gave him an industrial experience that helped to identify the basic principles of corporate life.",
      },
      {
        company: "MSigma Gokulam",
        position: "Director and CEO ",
        details:
          "As the Director and CEO of Msigma Gokulam, he is leading the launch of the ‘Zero to Infinity’ programme, a comprehensive initiative aimed at: Offering subject-oriented training across all engineering branches, Helping students crack competitive government exams such as GATE, IES, and Public Sector Undertakings, Preparing students for private sector placements and equipping them with industry-relevant skills.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          "Successfully cracked GATE multiple times and cleared prestigious exams like Indian Engineering Services (IES) and PSUs.",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 2
  {
    id: 2,
    name: "Dr. K. Renganathan",
    designation: "Academic Dean & Senior Faculty, MSigma Gokulam",
    profilePhoto: ranga,
    aboutMe:
      "Former senior scientist at Vikram Sarabhai Space Centre (VSSC) in ISRO, Dr. K. Renganathan is a wizard of science, and his knowledge and experience contribute to the growth of Msigma Gokulam. Specialised in Mathematics, he is a PhD scholar from the Jawaharlal Nehru Technological University of Hyderabad and has a long 40 years of experience in teaching that guides students to enter into the practical application of mathematical principles in their academic journey.",
    academics: [
      {
        qualifications:
          "PhD in Mathematics from Jawaharlal Nehru Technological University (2000)",
        institute: "Jawaharlal Nehru Technological University Hyderabad (2000)",
      },
      {
        qualifications: "Research Fellowship",
        institute: "Indian Institute of Technology, Madras (1982 - 1983)",
      },
      {
        qualifications: "Msc in Mathematics ",
        institute: "Thiagarajar College of Engineering, Madurai (1980 - 1982)",
      },
      {
        qualifications: "BSc in Mathematics",
        institute: "Arul Anandar College, Madurai (1977 - 1980)",
      },
    ],
    experience: [
      {
        company: "Vikram Sarabhai Space Centre (VSSC/ISRO)",
        position: "Senior Scientist, 1983 - 2020",
        details:
          "Dr. K. Renganathan, during his period at VSSC, concentrated on the advanced principles of mathematics where he assisted in the development of space research and aerospace engineering. His role was crucial in managing the complex projects at ISRO where the mathemtical skills were utilised for simplifying the tasks.",
      },
      {
        company: "MSigma Gokulam",
        position: "Academic Dean, 2021 - Present",
        details:
          "Dr. Renganathan has been leading the educational advancements at MSsgma through his skills in building a robust curriculum thereby providing a structure to the programmes. He thrives to utilise his experience for the engineering aspirants at the academy to be tomorrow’s masterminds in engineering.",
      },
    ],
    expertise: [
      {
        image: rnd,
        title: "Research and Development in Space Science",
      },
      {
        image: satelite,
        title: "Aerospace Engineering",
      },
      {
        image: satelite,
        title: "Satellite Technology and Space Mission Planning",
      },
      {
        image: acad,
        title: "Mathematical Modeling",
      },
    ],
    achievements: [
      {
        achievement: "Major achievements",
        description:
          "Research in remote sensing and space programmes, Project Guide at space events, Formulated study materials for prominent engineering colleges in India.",
      },
      {
        achievement: "Awards Received.",
        description:
          "‘Outstanding Scientist Award’ from ISRO for his contribution to space technology and development.",
      },
    ],
    publications: [
      "He has published more than 50 research papers in national and international journals with topics including space technology, engineering, and satellite development.",
    ],
    affiliations: [
      "Member of the Indian Space Research Organization (ISRO) Alumni Association",
      "Fellow of the Indian Society of Aerospace Technology and Sciences",
    ],
  },

  // 3
  {
    id: 3,
    name: "A V V B Shekhar",
    designation: "HOD,Mathematics- MSigma Gokulam",
    profilePhoto: shekhar,
    aboutMe:
      "A.V. V.B. SEKHAR is an exemplar of dedication and expertise that has been woven together, and he has been handing over his abilities to the students as a teacher at various reputed institutions. Having received the best teacher award from M.V.S.R Engineering College, he has also trained students all across the country for the GATE exam with 25 years of experience.",
    academics: [
      {
        qualifications: "ME in Thermal Engineering",
        institute: "Jawaharlal Nehru Technological University Hyderabad (2000)",
      },
      {
        qualifications: "BE in Thermal Engineering",
        institute: "Thiagarajar College of Engineering, Madurai (1980 - 1982)",
      },
    ],
    experience: [
      {
        company: "M.V.S.R Engineering College, Hyderabad",
        position: "Associate Professor (1989-2013)",
        details:
          "A.V. V.B. Sekhar has worked as an Associate Professor in Mechanical Engineering department of M.V.S.R. Engineering college, Hyderabad.",
      },
      {
        company: "VANI Institute",
        position: "Senior Visiting Faculty (2002-2009)",
        details:
          "He has been training students for the GATE exam and has made them victorious through his classes at the different centres of VANI Institute in Pune, Chennai and Bangalore.",
      },
      {
        company: "GATE FORUM , Pune",
        position: "Senior Gate Faculty (2009-2013)",
        details:
          "The professor has also made his presence at the GATE Forum, Pune and helped students reach greater heights with his perseverance and efforts.",
      },
      {
        company: "Msigma Gokulam",
        position: "HOD, Mathematics",
        details:
          "A.V. V. B Shekhar has been serving Msigma Gokulam as the HOD of Mathematics and helps the institution through his valuable advice and instructions.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Awards",
        description:
          "Award for best teacher in M.V.S.R. Engineering College, Hyderabad",
      },
      {
        achievement: "Achievements",
        description:
          "Selection Committee member and interview panelist at Gate coaching institutes and engineering colleges",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 4
  {
    id: 4,
    name: "Charlies Leo",
    designation: "HOD, Civil Engineering, Msigma Gokulam",
    profilePhoto: charlies,
    aboutMe:
      "Charles Leo Panadan is at the forefront of Msigma Gokulam’s activities and is the head of the Civil Engineering Department. After graduating in 2011, he has worked in various fields including the Public Works Department of Kerala and the military engineering services. Moreover, he is experienced in all subjects for GATE and his familiarity with the role of an edupreneur will be pivotal for the company’s growth.",
    academics: [
      {
        qualifications: "B.Tech in Civil Engineering",
        institute: "College of Engineering, Thiruvananthapuram (2007-11)",
      },
    ],
    experience: [
      {
        company: "Amal Jyothi College of Engineering, Kanjirappally.",
        position: "GATE Faculty (2012-13)",
        details:
          "Charlies Leo Panadan was an assistant professor at Amal Jyothi College of Engineering along with the position of a coordinator for coaching exams.",
      },
      {
        company: "Military Engineering Services.",
        position: "Junior Civil Engineer (2014-15)",
        details:
          "Charlies Leo Panadan also served in Delhi as a Junior Civil Engineer in the Military Engineering Services that enhanced his skillset.",
      },
      {
        company: "Kerala Public Works Department.",
        position: "Assistant Engineer (2017-23)",
        details:
          "He was part of several major projects as a highway engineer and bridge engineer which gave him an opportunity to nurture the skills and contribute to the activities.",
      },
      {
        company: "Echelon Institute of Technology.",
        position: "Managing Director",
        details:
          "He led the coaching programmes at Echelon to make the students comfortable with the exams including PSC, SSC, and GATE.",
      },
      {
        company: "MSigma Gokulam",
        position: "Chief Career Strategist (2023-24)",
        details:
          "Charlies Leo Panadan started his career at Msigma as a chief career strategist and contributed in building a strong foundation for the company’s future.",
      },
      {
        company: "MSigma Gokulam",
        position: "HOD, Civil Engineering (2024-Present)",
        details:
          "As a head of the civil engineering department, Charlies Leo Panadan is currently the face of the department and guides the team with his experience and talent.",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 5
  {
    id: 5,
    name: "Rajarao M V [SHASTRI]",
    designation: "Chief Academic Strategist",
    profilePhoto: rajarao,
    aboutMe:
      'Rajarao MV is the chief academic strategist at Msigma Gokulam who develops a structure for all the academic programmes of the institution apart from guiding the faculty. He also becomes a model for the whole faculty by being a role model in teaching and developing individual connections with the students.',
    academics: [
      {
        qualifications: "M.Tech",
        institute: "IIT Madras",
      },
    ],
    experience: [
      {
        company: "Prepladder",
        position: "Senior Faculty",
        details:
          "Rajarao worked in Prepladder as a faculty where he had the opportunity to teach students how to crack exams in a systematic manner. Here, he developed the skills required for teaching and understanding the classroom dynamics.",
      },
      {
        company: "Veramasa",
        position: "Managing Director",
        details:
          "Rajarao was the Managing Director of Veramasa where he led the company in its whole operations and acquired the practical experience required for leading an establishment. The company’s focus on technological solutions was amplified by his ideal solutions in engineering services.",
      },
      {
        company: "MSigma Gokulam",
        position: "Chief Academic Strategist and Faculty",
        details:
          "Rajarao is currently the chief academic strategist at Msigma Gokulam apart from being an active teacher among students. His strategies and experience assist the institution in becoming a key player in the edtech industry providing quality education.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements at  IES ",
        description:
          "All India Rank 6 in 2010",
      },
      {
        achievement: "Notable Achievements at  GATE EC ",
        description:
          "All India Rank 296 in 2006 and All India Rank 54 in 2007",
      },
      {
        achievement: "Notable Achievements at  GATE EEE ",
        description:
          "All India Rank 420 in 2010",
      },
      {
        achievement: "Patents",
        description:
          "Filed for 2 patents in Biomedical Signal processing ",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 6
  {
    id: 6,
    name: "V S R Suresh",
    designation: "GATE Faculty,",
    profilePhoto: vsr,
    aboutMe:
      'VSR Suresh is a GATE Faculty at Msigma Gokulam who shines in his role with a previous teaching experience of 24 years. He has also excelled in coaching for the GATE and ESE exams at various institutions and is good in the field.',
    academics: [
      {
        qualifications: "M.Tech in Computer Science and Engineering ",
        institute: "JNTU Hyderabad",
      },
      {
        qualifications: "B.Tech in Electronics and Communication Engineering ",
        institute: "Nagarjuna University ",
      },
    ],
    experience: [
      {
        company: "Made Easy",
        position: "Faculty",
        details:
          "VSR Suresh was a faculty for the GATE exam at the Made Easy academy where he coached students in preparing for the exam. He has a good track record here with the majority of the students cracking the exam.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "VSR Suresh’s role at Msigma Gokulam has been pivotal with him being an experienced teacher in the field. He makes an effort to lead from the front and assists the students in moulding their skills.",
      },
    ],
    expertise: [
      {
        image: elctro,
        title: " Electromagnetics",
      },
    ],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 7
  {
    id: 7,
    name: "K V S V Trinath Reddy",
    designation: "GATE Faculty",
    profilePhoto: trinath,
    aboutMe:
      'Being a veteran teacher, K V S V Trinath Reddy is a repository of knowledge and delivers quality lectures at Msigma Gokulam. He has been an assistant professor in several engineering colleges and worked as a  GATE faculty in reputed coaching centers all across India.',
    academics: [
      {
        qualifications: "PhD in Signal processing (Submitted thesis and waiting for final viva)",
        institute: "University, Tirupati ",
      },
      {
        qualifications: "M.E in Electronics and Instrumentation ",
        institute: "College of Engineering, Andhra University",
      },
      {
        qualifications: "B.Tech ",
        institute: "RGCET, Jawaharlal Nehru Technological University, Andhra Pradesh (1995-99)",
      },
    ],
    experience: [
      {
        company: "Cambridge Institute of Technology, Bangalore",
        position: "Associate Professor (2011-13)",
        details:
          "Trinath Reddy was an associate professor at the Cambridge Institute of Technology for two years where he was able to enhance his skills in teaching. Moreover, it helped him with having contact with professional models in education and in teaching diverse students.",
      },
      {
        company: "Unacademy",
        position: "GATE Faculty",
        details:
          "Trinath Reddy was a leading educator at Unacademy, one of the leading edtech platforms in India. There, he equipped students to crack the GATE exam and was able to develop individual skills in teaching.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Trinath Reddy operates as a GATE Faculty in Msigma Gokulam where his expertise and experience assist the institution in developing a credible position. He has also excelled in managing the syllabus and preparing notes that are useful for the students.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Academic Achievements",
        description:
          "Bronze medal in National Maths Olympiad",
      },
      {
        achievement: "Notable Achievements ",
        description:
          "Best Faculty of Department award from GIET, Rajahmundry (2001)",
      },
      {
        achievement: "Notable Achievements ",
        description:
          "Best Faculty of Department award for 5 consecutive academic years from SNIT, Hyderabad (2005-10)",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 8
  {
    id: 8,
    name: "T Nithin",
    designation: "GATE Faculty",
    profilePhoto: nithin,
    aboutMe:
      'T Nithin is an experienced faculty at Msigma Gokulam having a strong academic background and high score in the GATE exam. Working in the Department of Electronics and Communication Engineering, he has been a major source for the institution in its progression and continues to contribute to the team.',
    academics: [
      {
        qualifications: "M.Tech in  Electronics and Communication Engineering",
        institute: "NIT Calicut, 2013",
      },
      {
        qualifications: "B.Tech in Electronics and Communication Engineering (Tele-Communication).",
        institute: "SICET Hyderabad, 2010",
      },
      
    ],
    experience: [
      {
        company: "Msigma Gokulam",
        position: "Faculty, GATE",
        details:
          "T Nithin has been an exceptional talent for Msigma Gokulam, with his academic knowledge empowering the institution’s fame and glory. He works in the Electronics and Communication Engineering department where he utilises these skills for the upliftment of both the students and the institution.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "GATE AIR 88",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 9
  {
    id: 9,
    name: "V. Bala Murali Krishna",
    designation: "GATE Faculty",
    profilePhoto: bala,
    aboutMe:
      'V. Bala Murali Krishna is a renowned academician and is currently working as a GATE faculty at Msigma Gokulam. He has wide exposure to teaching at different colleges and worked in multiple academics as an expert in GATE coaching.',
    academics: [
      {
        qualifications: "MSc in Applied Mathematics ",
        institute: "S.V. University. Thirupathi",
      },
      {
        qualifications: "BSc ",
        institute: "Hawahar Bharathi Degree College, Kavali",
      },
    ],
    experience: [
      {
        company: "ACE Engineering Academy, Hyderabad",
        position: "Faculty",
        details:
          "V. Bala Murai Krishna worked at ACE Engineering Academy where he had exposure to bright students and helped them develop their careers. Here, he concentrated on training students on GATE and ECE apart from providing them with continuous support.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "He has been a GATE Faculty at Msigma Gokulam and works in tandem with the rest of the staff to improve the institution’s performance. His teaching practice from various parts of the country helped him develop a connection with the students and go on with the activities.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          "All India Rank- 192",
      },
      {
        achievement: "Academic Achievements",
        description:
          "Organised state and national-level symposiums and quiz competitions",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 10
  {
    id: 10,
    name: "Vaka Raghav Reddy",
    designation: "Senior GATE Faculty",
    profilePhoto: vaka,
    aboutMe:
      'Vaka Raghav Reddy works as a senior GATE faculty in the Mechanical Engineering Department of Msigma Gokulam where his expertise helps the institution provide better education. Furthermore, his dedication to career-focused teaching empowers students to build successful futures.',
    academics: [
      {
        qualifications: " B.Tech ",
        institute: "Jawaharlal Nehru Technological University Hyderabad (2000)",
      },
    ],
    experience: [
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Vaka Raghav Reddy has been a credit to Msigma Gokulam with his academic expertise and professional experience alluring more students. He also continues to learn from the institution and utilise the knowledge for the students in their career goals.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "GATE AIR 86",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 11
  {
    id: 11,
    name: "Prasanna Kumar",
    designation: "GATE Faculty",
    profilePhoto: prasanna,
    aboutMe:
      'Working as a Gate Faculty in the Department of Mechanical Engineering department, Prasanna Kumar is an experienced faculty, having given classes to thousands of students across India. Furthermore, he is also efficient in providing the right guidance to students and in leading them based on their unique skills.',
    academics: [
      {
        qualifications: " M.Tech ",
        institute: "IIT BHU Varanasi.",
      },
      {
        qualifications: " B.Tech ",
        institute: "",
      },
    ],
    experience: [
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Prasanna Kumar had 10 years of experience before joining Msigma and he utilised this in the institution for crafting young engineers. Moreover, he was also able to improve his skills and enhance his knowledge in the field.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "AIR- 1823",
      },
      {
        achievement: "IIT JEE",
        description:
          "AIR- 623",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 12
  {
    id: 12,
    name: "Ranga Reddy",
    designation: "GATE Faculty",
    profilePhoto: rangaReddy,
    aboutMe:
      'Ranga Reddy works in the Civil Engineering Department of Msigma Gokulam as a GATE Faculty and assists in the organisational development. He has eleven years of experience in various institutions and has cracked the GATE exam with a good rank.',
    academics: [
      {
        qualifications: "M.Tech",
        institute: "Jawaharlal Nehru Technological University Kakinada",
      },
      {
        qualifications: "B.Tech",
        institute: "Indian Institute of Technology, Madras",
      },
    ],
    experience: [
      
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Ranga Reddy has helped Msigma with his subject knowledge which he transferred to the students and assisted in updating the syllabus. His valuable suggestions have helped the Civil Engineering Department to emerge as an ideal sector in Msigma.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          " AIR 126 in the year 2013",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 13
  {
    id: 13,
    name: "Naveen Gollapally",
    designation: "GATE Faculty",
    profilePhoto: naveen,
    aboutMe:
      'Naveen is active in Msigma Gokulam’s Electrical and Electronics Engineering department where he functions in line with the institution’s values and goals. Having a diverse set of professional experience and academic prowess, his presence is of real value to Msigma in developing its future.',
    academics: [
      {
        qualifications: " M.Tech in Electrical Power Systems ",
        institute: "JNTU, Hyderabad",
      },
      {
        qualifications: " B.Tech in Electrical and Electronics Engineering ",
        institute: "VJIT, Hyderabad",
      },
    ],
    experience: [
      {
        company: "GATEFORUM",
        position: "Faculty (2014-2019) ",
        details:
          "Naveen worked at GATEFORUM for 5 years, which makes him a competitive and talented figure in the field. Here, he managed and interacted with students from various backgrounds and obtained actual teaching training.",
      },
      {
        company: "GradeUp",
        position: "Faculty (2019-2023) ",
        details:
          "Naveen worked in Gradeup for four years where he assisted students in their academic growth and provided mentorship. This experience made him capable of leading from the front and developing a skill in the management of lectures.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty ()",
        details:
          "Naveen Gollapally at Msigma Gokulam makes his presence by utilising his experience in GATE coaching where he helps them crack the exam. Moreover, he also maintains individual relationships with the students and the other faculty members to ensure a healthy atmosphere.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "GATE 2012 Rank: 4954",
      },
      {
        achievement: "Electrical Engineering",
        description:
          "Topper in Electrical Machines",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 14
  {
    id: 14,
    name: "Betharaju Surendra Yadav",
    designation: "GATE Faculty",
    profilePhoto: s_yadhav,
    aboutMe:
      'Betharaju Surendra Yadav is a prominent GATE Faculty of Msigma Gokulam with a solid experience of 11 years in the teaching profession and over 2500+ disciples all over India. His skills and experience aid the institution in developing its resources and updating them to suit the contemporary atmosphere in the educational sector.  ',
    academics: [
      {
        qualifications: "M.Tech  ",
        institute: "Sree Venkateswara University, Andra Pradesh (2008-10)",
      },
      {
        qualifications: "B.Tech  ",
        institute: "",
      },
    ],
    experience: [
      {
        company: "Unacademy",
        position: "Senior Educator",
        details:
          "B. Surendra Yadav worked in Uncademy and trained students in different exams such as GATE and ESE. He also used the time here as an opportunity to magnify the skills acquired and get real-time knowledge on the coaching models and teaching principles.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "B. Surendra Yadav provides his valuable assistance to the Department of  Electrical and Electronics Engineering at Msigma Gokulam. Here, his teachings have been useful for the students in staying updated with the trends in the engineering sector and the nature of competitive exams. ",
      },
    ],
    expertise: [
      {
        image:powerElectrical,
        title: "Power Electricals",
      },
      {
        image: measure,
        title: "Electrical and Electronics Measurements",
      },
      {
        image: networkTheory,
        title: " Network Theory",
      },
      {
        image: powerSystem,
        title: " Power System",
      },
      {
        image: controlSystem,
        title: "   Control System",
      },
      {
        image: transducers,
        title: " Transducers and Mechanical Instrumentation",
      },
    ],
    achievements: [
      {
        achievement: "Major achievements",
        description:
          "Gold medal in M.Tech from SVU, Andhra Pradesh (2008-10)",
      },
      {
        achievement: "Achievement in GATE",
        description:
          "GATE 2008- 900",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 15
  {
    id: 15,
    name: "G Vinod Kumar",
    designation: "GATE Faculty",
    profilePhoto: vinod,
    aboutMe:
      'G Vinod Kumar is a prominent GATE Faculty member at Msigma Gokulam. His key strength lies in motivating students to do their best. With more than six years of teaching experience, he has been successful in influencing students to crack the GATE exam and is always available to assist them.',
    academics: [
      {
        qualifications: "Ph.D. in [Specialisation]",
        institute: "Jawaharlal Nehru Technological University Hyderabad (2000)",
      },
      {
        qualifications: "MSc in [Subject]",
        institute: "Thiagarajar College of Engineering, Madurai (1980 - 1982)",
      },
      {
        qualifications: "BSc in [Subject]",
        institute: "Arul Anandar College, Madurai (1977 - 1980)",
      },
      {
        qualifications: "Research Fellowship",
        institute: "Indian Institute of Technology, Madras (1982 - 1983)",
      },
    ],
    experience: [
      {
        company: "MSigma Gokulam",
        position: "Academic Dean, 2021 - Present",
        details:
          "As the Academic Dean at M Sigma Gokulam, Dr. Renganathan has been instrumental in designing and implementing advanced curricula, fostering an environment of academic excellence, and mentoring the next generation of engineers and scientists",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          " AIR - 674",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 16
  {
    id: 16,
    name: "B Mojya",
    designation: " GATE Faculty",
    profilePhoto: mojya,
    aboutMe:
      'B. Mojya is a GATE Faculty at Msigma Gokulam where he makes use of his teaching abilities for helping students understand complex problems. Having thirteen years of experience in the field he is also known for the mentoring skills that assist students in facing exams without any mental obstacles.',
    academics: [
      {
        qualifications: "M.Tech in Structural Engineering ",
        institute: " IIT, Kharagpur",
      },
      {
        qualifications: "B.Tech in Civil Engineering ",
        institute: "JNTUH Colege, Hyderabad",
      },
    ],
    experience: [
      {
        company: "ACE Engineering Academy, Hyderabad",
        position: "Faculty",
        details:
          "Mojya served at ACE Engineering Academy for ten years and immersed himself in helping students strengthen their careers. He focused on the GATE exam and developed a thorough knowledge of it thereby developing a structure that simplified the process.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE faculty",
        details:
          "As a GATE faculty at Msigma, B. Mojya plays a major part in its development through multiple contributions as a teacher and through his interventions in structuring the coaching patterns.  He also helps students manage their time and answer the exam without any pressure by aligning with the institution’s goals.",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 17
  {
    id: 17,
    name: "Muralikrishna Bukkasamudram",
    designation: "GATE Faculty",
    profilePhoto: murali,
    aboutMe:
      'Murali Krishna Bukkasamudram is an inevitable faculty member of Msigma Gokulam where his role as a GATE Faculty has aided it in moving forward and providing quality education. He can handle six subjects in Computer Science and Engineering and has 23 years of experience in teaching which makes him valuable in the field.',
    academics: [
      {
        qualifications: "M.Tech",
        institute: "",
      },
      {
        qualifications: "International Engineering Education",
        institute: " IIEECP",
      },
    ],
    experience: [
      {
        company: "TIME",
        position: "GATE Faculty (2014-20)",
        details:
          "He excelled as a faculty in TIME and was giving classes focusing on Computer Science and Engineering. He gave classes on both offline and online modes which reached a large audience and widened his fame.",
      },
      {
        company: "Hyderabad Institute of Technology and  Management",
        position: "Assistant Professor (2017-2020)",
        details:
          "Murali Krishna Bukkasamudram worked for three years as an assistant professor which gave him the modern skills needed in teaching. It was also useful in managing student-teacher relations and helping them learn beyond the classroom knowledge.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Murali Krishna Bukkadsamudram as a GATE Faculty in Msigma has been a valuable member who gives his best to the institution’s progress. His experience in teaching and coaching classes continues to assist Msigma in crafting new strategies.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          "GATE and UGC NET qualified",
      },
      {
        achievement: "Academic Achievements",
        description:
          " NPTEL gold medalist",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 18
  {
    id: 18,
    name: "Prasanth Kumar Kandregula",
    designation: " GATE and IIT JEE Faculty",
    profilePhoto: prasanth,
    aboutMe:
      'Prasanth Kumar Kandregula works in the Electrical and Electronics Engineering department of Msigma Gokulam where he manages both GATE and JEE classes. His expertise in physics and mathematics along with his years of coaching experience makes him inevitable and invincible in the field.',
    academics: [
      {
        qualifications: " M.Tech in Control and Instrumentation Engineering ",
        institute: "NIT Jalandhar",
      },
      {
        qualifications: " B.Tech in Electrical and Electronics Engineering",
        institute: "Andhra University",
      },
    ],
    experience: [
      {
        company: "Kota Classes",
        position: "Faculty (2016-2018) ",
        details:
          "Prashant Kumar was an active Faculty in the Kota classes for IIT JEE where he focused on teaching physics for the higher secondary students.",
      },
      {
        company: "IMS GATE Academy",
        position: "GATE Faculty (2018-2020) ",
        details:
          "Prasanth Kumar was a GATE Faculty in IBS GATE academy for two years where he excelled in training students in the GATE Exam. Here, he also displayed his abilities in teaching control systems and the operations of electrical machines.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Prashant Kumar Kandregula with his skills in teaching practice has been supporting Msigma Gokulam and its students with his efforts and contributions in the field. Having a diverse set of students in his previous experiences has been useful for him here in training the students.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements at GATE",
        description:
          "Cracked GATE Exam multiple times and Best Rank: AIR 2196",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 19
  {
    id: 19,
    name: "Abhijin Sreekantan",
    designation: "GATE Faculty",
    profilePhoto: abhijin,
    aboutMe:
      'Abhijin Sreekantan as a GATE faculty at Msigma Gokulam is a master in teaching and has experience in preparing students for the GATE exam. He has contact with the top GATE faculties and utilises these connections for the institution’s progress. ',
    academics: [
      {
        qualifications: "PhD",
        institute: "Indian Insitute of Remote Sensing, ISRO (2018)",
      },
      {
        qualifications: "M.Tech in Environmental Engineering Technology",
        institute: "NIFFT (2016-18)",
      },
      {
        qualifications: "B.Tech in Civil engineering ",
        institute: " APJ Abdul Kalam Technological University (2010-14)",
      },
    ],
    experience: [
      {
        company: "Kerala State Pollution Control Board",
        position: "Assistant Engineer (2021)",
        details:
          "Abhijin worked for two years in the Kerala State Pollution Control Board as an assistant engineer where he was able to run multiple projects. This made him able to acquire operational skills in project management and handle difficult tasks within the time limits.",
      },
      {
        company: "BYJU’S ",
        position: "Associate Director- GATE (2021-23)",
        details:
          "At BYJU’S, Abhijin coordinated the GATE programmes and obtained industrial experience for understanding the structure behind the programme. Moreover, it also helped him learn the basics of the GATE programme and the precautions to be taken for the classes.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty",
        details:
          "Abhijin Sreekantan contributes to Msigma Gokulam as a GATE faculty where he makes use of his experience and skills to enhance its fame. Furthermore, his knowledge and expertise also assist the students in developing a unique approach to their studies and cracking the exam.",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 20
  {
    id: 20,
    name: "Vishnu Vardhan",
    designation: "GATE Faculty",
    profilePhoto: vishnu,
    aboutMe:
      'P Vishnu Vardhan is a GATE Faculty at Msigma Gokulam who has a previous experience of 13 years in teaching and special expertise in GATE coaching. Working in the Department of Electrical Engineering, he is good at finding answers to complex problems and simply conveying them for the students to understand them.',
    academics: [
      {
        qualifications: " M.Tech in Electrical Power Systems",
        institute: "",
      },
      {
        qualifications: "B.Tech in Electrical and Electronics Engineering",
        institute: "",
      },
    ],
    experience: [
      {
        company: "SV College",
        position: "Faculty",
        details:
          "Vishnu Vardhan served 13 years at SV College where he developed skills in interacting with students and gaining coordination abilities.",
      },
      {
        company: "Gate Forum",
        position: "Faculty",
        details:
          "He was a faculty at GATE Forum for 6 years, which is one of the India’s leading platforms for cracking GATE.",
      },
      {
        company: "Engineer’s Circle",
        position: "Faculty",
        details:
          "Vishnu Vardhan served as a Faculty at Engineer’s Circle for 6 years and helped students face the exam by providing rigorous training.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE Faculty.",
        details:
          "P Vishnu Vardhan’s contributions to Msigma as a GATE faculty has helped the institution to promote its programmes and attract more students. He has also assisted in developing quick solution to the academic challenges and working in inion with the team to generate answers.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "GATE Rank- 369",
      },
    ],
    publications: [],
    affiliations: [],
  },
  
  // 21
  {
    id: 21,
    name: "Dipin Venugopal",
    designation: "B.Tech Faculty",
    profilePhoto: dipin,
    aboutMe:
      'Dipin Venugopal is a talented faculty of Msigma having a solid professional background in international companies. He transfers the skills acquainted from these areas in providing quality education to the students.',
    academics: [
      {
        qualifications: "MBA ",
        institute: "Cardian Metropolitan University, UK",
      },
      {
        qualifications: "B.Tech ",
        institute: "Government Engineering College, Kannur",
      },
    ],
    experience: [
      {
        company: "Jacobs UK",
        position: "Financial Analyst",
        details:
          "Dipin worked as a Financial Analyst at Jacobs UK, where he was able to deal with the complexities in the financial settings of an organisation. It also helped him develop a skill of analytical thinking and attention to detail thereby enhancing his skillset.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Dipin Venugopal is a B.Tech Faculty in the Msigma Gokulam where he functions in providing education and helping students find novel opportunities. His teachings also aid the institution in improving its operations and gaining more acclaim. ",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 22
  {
    id: 22,
    name: "Arjun M A",
    designation: "GATE Faculty, Msigma Gokulam",
    profilePhoto: arjun,
    aboutMe:
      'Arjun M.A. is a valuable faculty of Msigma Gokulam in the mechanical engineering department. He is a former IIT student and has cracked the GATE Exam with a good score that he utilises in the institution to aid the students.',
    academics: [
      {
        qualifications: "M.Tech in Mechanical Engineering",
        institute: "IIT GUWAHATI, 2020",
      },
      {
        qualifications: "B.Tech in Mechanical Engineering",
        institute: "IIT GUWAHATI, 2020",
      },
      
    ],
    experience: [
      {
        company: "SCT College of Engineering, Thiruvananthapuram",
        position: "Assistant Professor (2021-2022)",
        details:
          "Served as an assistant professor at SCT College of Engineering thereby having touch with the ignited youth.",
      },
      {
        company: "MSigma Gokulam",
        position: "GATE faculty",
        details:
          "",
      },
    ],
    expertise: [],
    achievements: [
      
      {
        achievement: "Academic achievements",
        description:
          "GATE 2018 AIR 1772 and Scored 749 marks",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 23
  {
    id: 23,
    name: "Athira A",
    designation: "B.Tech Faculty",
    profilePhoto: athira,
    aboutMe:
      'Athira works as a B.Tech Faculty member in Msigma Gokulam where she is knowledgeable in the Applied Electronics and Instrumentation branch. Having a good academic track record and teaching experience, she is an asset to the institution and to the students.',
    academics: [
      {
        qualifications: "M.Tech Electronics and Communication Systems",
        institute: "NIT Rourkela (2016-18)",
      },
      {
        qualifications: "B.Tech Applied Electronics and Instrumentation Engineering.",
        institute: "GEC Kozhikode (2011-15)",
      },
    ],
    experience: [
      {
        company: "SCT College of Engineering, Thiruvananthapuram",
        position: "Assistant Professor",
        details:
          "Athira was a guest lecturer at the SCT College of Engineering for six months and is able to handle students with novel practices. It equipped her to learn the basics of teaching and get practical experience for professional improvement.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Athira has been a valuable member of Msigma Gokulam where she contributes to the institution by fulfilling her duties as B.Tech Faculty. Being a young faculty member with vibrant character she is truly an asset to Msigma, with students relying on her teaching skills.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE 2020",
        description:
          "All India Rank: 134",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 24
  {
    id: 24,
    name: "Aryadutt C S",
    designation: "GATE Faculty and Mentor",
    profilePhoto: arya,
    aboutMe:
      'Working in the department of Mechanical Engineering at Msigma Gokulam as a GATE Faculty and Mentor, Aryadutt C.S. is known for his skills in solving complex problems and delving deep into the engineering challenges. Having completed his M.Tech and B.Tech from NIT Calicut, he has experience in coordinating classes, conducting training programmes for GATE coaching and preparing study materials.',
    academics: [
      {
        qualifications: "M.Tech in Industrial Engineering ",
        institute: "NIT Calicut (2016-18)",
      },
      {
        qualifications: "B.Tech in Mechanical Engineering ",
        institute: "NIT Calicut (2011-15)",
      },
    ],
    experience: [
      {
        company: "Futton Learning",
        position: "Course Coordinator (2021-22)",
        details:
          "Aryadutt worked as a course coordinator at Futton Learning, where he got experience in learning the functions of academic systems and coordinating the course structure. It also made him efficient in working in association with a team and setting goals to meet the organisational requirements.",
      },
      {
        company: "MSigma Gokulam",
        position: "Gate Faculty and Mentor",
        details:
          "Aryadutt joined Msigma Gokulam in 2022 where he excels in assisting students crack GATE exam apart from performing the role of a mentor. His skills have benefited the institution in attaining a professional image in teaching and gaining credibility in solving complex problems.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE ME",
        description: "2022 GATE Rank- 1185",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 25
  {
    id: 25,
    name: "Afsal Haleef",
    designation: "Faculty",
    profilePhoto: afsal,
    aboutMe:
      'Afsal Haleef is an experienced faculty at Msigma Gokulam with 14 years of teaching experience. He has been giving tuition to students for various exams including GATE and has also excelled in B.Tech classes, especially in the Mechanical department.',
    academics: [
      {
        qualifications: "B.Tech in Mechanical Engineering",
        institute: "MACE, Venjaramoodu",
      },
    ],
    experience: [
      {
        company: "MSigma Gokulam",
        position: "Faculty",
        details:
          "Afsal Haleef has been supporting Msigma through his knowledge and teaching skills where he is the major attraction of the Mechanical department. He is a real manifestation of dedication and perseverance and is always there to give his best to the students.",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 26
  {
    id: 26,
    name: "Renjen Roy RU",
    designation: "B.Tech Faculty",
    profilePhoto: renjan,
    aboutMe:
      'Renjen Roy has been a B.Tech Faculty at Msigma Gokulam since 2021 and is a pivotal part of the institution with his skills in coordinating subjects and teaching them with accuracy.  He is experienced in teaching at engineering colleges and is also acquainted with the features of corporate realms.',
    academics: [
      {
        qualifications: "M.Tech",
        institute: "SCT College of Engineering, Trivandrum (2013-15)",
      },
      {
        qualifications: "B.Tech",
        institute: "Govt. College of Engineering, Barton Hill, Thiruvananthapuram (2008-2012)",
      },
    ],
    experience: [
      {
        company: "IIITMK, Technopark, Thiruvananthapuram",
        position: "Project Assistant",
        details:
          "Renjen worked as a project assistant in IITMK, Technopark where he had the opportunity to manage multiple projects and deal with the organisational tasks. This developed a skill of teamwork and coordination that boosted his abilities and in getting a knowledge on professionally managing the tasks.",
      },
      {
        company: "SCTCE, Thiruvananthapuram",
        position: "Assistant Professor (2017-21)",
        details:
          "Renjen was an assistant professor at Sree Chitra Tirunal College of Engineering for 4 years where he became efficient in teaching. Here, he also became efficient in maintaining a strong student-teacher relationship and in developing a style that matches the classroom.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty (2021-Present)",
        details:
          "Renjen Roy contributes to Msigma with his unique skills in transferring knowledge to students without any complexity. He has also been successful in making the students believe in their potential and designing teaching models to help them. ",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          "Qualified GATE exam multiple times",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 27
  {
    id: 27,
    name: "Padmaja P L",
    designation: "B.Tech Faculty",
    profilePhoto: padmaja,
    aboutMe:
      "Padmaja P. L. worked as a B.Tech Faculty in Msigma Gokulam and shared her valuable experiences and knowledge with the students. Having more than 10 years of teaching experience, she possessed incredible skills and achievements that enhanced the institution's name. ",
    academics: [
      {
        qualifications: "M.Tech in Power System and Control",
        institute: "Kerala University",
      },
    ],
    experience: [
      
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Padmaja’s career in Msigma as a B.Tech faculty was important for the institution in its growth and reputation. It was an inspiration for the students to learn from her and she was also able to influence them to hope for the future. Moreover, her teaching experience has also been useful in providing the best education for students.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in Academics",
        description:
          "Achieved 8th rank in M.Tech in Power System and Control from Kerala University",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 28
  {
    id: 28,
    name: "Akhila Raj",
    designation: "B.Tech Faculty",
    profilePhoto: akhila,
    aboutMe:
      "Akhila Raj is part of Msigma Gokulam’s Civil Engineering department and has been actively supporting the departmental programmes with her organisation skills and knowledge in the stream. With a strong educational background and professional experience, her contributions continue to elevate the institution's standards.",
    academics: [
      {
        qualifications: "M.Tech in Environmental Engineering",
        institute: "NIT Karnataka, Surathkal (2022-24)",
      },
      {
        qualifications: "B.Tech in Civil Engineering",
        institute: "APJ Abdul Kalam Technological University (2016-20)",
      },
    ],
    experience: [
      {
        company: "EKK Infrastructure",
        position: "Assistant Planning Engineer (2020-22)",
        details:
          "Akhila began her career at EKK Infrastructure where she was able to participate and lead in multiple projects and boost her engineering skills. Her work on the company’s projects helped build a robust infrastructure and strengthened her practical knowledge",
      },
      {
        company: "MSigma Gokulam",
        position: "Civil Engineering Faculty (2024-Present)",
        details:
          "Since joining Msigma Gokulam, Akhila has excelled in her role and maintains a positive rapport with both management and students. As a representative of the new-age teachers, she designs her classes to suit the interests of students without sacrificing the quality of classes.",
      },
    ],
    expertise: [
      {
        image: reservoir,
        title: "Reservoir Operation",
      },
      {
        image: oxdn,
        title: "Oxidation Technology",
      },
    ],
    achievements: [
      {
        achievement: "GATE",
        description:
          "Rank holder in CE&ES papers GATE 2021-22",
      },
      {
        achievement: "Awards",
        description:
          "Best Project presentation winner in Civil Engineering Inter-college Tech Festival (2020) and Kerala State Award Winner for Environmental Short Film (2015).",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 29
  {
    id: 29,
    name: "Betsy Victor",
    designation: "B.Tech Faculty",
    profilePhoto: betsy,
    aboutMe:
      'Betsy Victor is known for her immersive nature of teaching and maintaining a strong bond with the students apart from being an active faculty member of Msigma Gokulam. Working in the Department of Civil Engineering, she utilises her abilities to strengthen the course structure and pass on her expertise in cracking competitive exams.',
    academics: [
      {
        qualifications: " M.Tech in Geotechnical Engineering",
        institute: "IES Engineering College, Thrissur",
      },
      {
        qualifications: " B.Tech in Civil Engineering",
        institute: "College of Engineering and Management, Punnapra",
      },
    ],
    experience: [
      {
        company: "Axis Engineering College, Thrissur.",
        position: "Assistant Professor",
        details:
          "Betsy has experience in several private colleges as an assistant professor which enhanced her teaching skills. She also taught in several polytechnic colleges and has more exposure to practical teaching.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Betsy has been serving Msigma Gokulam in the Civil engineering department where she puts all her efforts into contributing to the students. As an exemplar of dedication and perseverance, she has been an inspiration for the team and the favourite of students. ",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements ",
        description:
          " Cleared several exams of Kerala PSC and other competitive exams.",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 30
  {
    id: 30,
    name: "Radhika Panickar",
    designation: "B.Tech faculty",
    profilePhoto: radhika,
    aboutMe:
      'Radhika Panickar served at Msigma as a B.Tech faculty member in Electronics and Communication, where she displayed her knowledge and assisted students. She has a Ph.D. from the University of Calicut and has valid teaching experience from reputed colleges. She is currently a postdoctoral research fellow at Tuskegee University in the United States.',
    academics: [
      {
        qualifications: "PhD ",
        institute: "Calicut University (2016-21)",
      },
      {
        qualifications: "PG Diploma in Counselling Psychology",
        institute: "University of Kerala (2013-14)",
      },
      {
        qualifications: "M.Tech in Nanotechnology",
        institute: "Vellore Insitute of Technology (2010-12)",
      },
      {
        qualifications: "B.Tech in Applied Electronics and Instrumentation",
        institute: "University of Kerala (2005-2009)",
      },
    ],
    experience: [
      {
        company: "St.Thomas Insitute of Science and Technology",
        position: "Assistant Professor (2012-2016)",
        details:
          "Radhika served as an Assistant Professor in the Department of Electronics and Communication at St.Thomas Institute of Science and Technology, Kattaikonam. Her time there was useful in helping her adjust to the classroom environment and develop rapport with the students.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Radhika’s association with Msigma Gokulam was beneficial in getting a professional outlook for the institution. She connected well with the students and helped them in clearing doubts apart from giving professional advice from her academic experience. Her contributions helped the Electronics and Communication Department to raise its quality and emerge as a central pillar of Msigma.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements ",
        description:
          "Certificate of Excellence from Elsevier (2024)",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 31
  {
    id: 31,
    name: "Anju EC",
    designation: "B.Tech Faculty",
    profilePhoto: anju,
    aboutMe:
      'Anju worked as a B.Tech Faculty in Msigma Gokulam where she received chances to enhance her knowledge and contribute to the students simultaneously.  She has worked in several colleges as an assistant professor and is adept in her teaching skills.',
    academics: [
      {
        qualifications: "M.Tech in Traffic and Transportation Planning",
        institute: "NIT Calicut (2016-18)",
      },
      {
        qualifications: "B.Tech in Civil Engineering",
        institute: "Mar Athanasius College of Engineering (2011-15)",
      },
    ],
    experience: [
      {
        company: "Government Engineering College, Wayanad",
        position: "Assistant Professor (2019)",
        details:
          "Anju got a chance to work as an assistant professor at Government Engineering College, Wayanad. Here, she got opportunities to handle lab sessions that were important for her in getting practical training and adding to her achievements. Moreover, she also mentored the students which was a new experience in her career and it also developed her interpersonal skills.",
      },
      {
        company: "Wincentre",
        position: "Assistant Professor (2020)",
        details:
          "Anju’s period at WIncentre helped her assist students in cracking various competitive exams and increasing her knowledge. Here, she gave lectures and also prepared notes for the classes including PSC coaching.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Anju E. C. was an active faculty of Msigma Gokulam and did her role as a B.Tech faculty, exceptionally well. Here, she prepared notes and led the classes with complete support and attention from the students. Furthermore, the institution gave her the exposure to develop a professional approach to teaching and maintaining relations.",
      },
    ],
    expertise: [],
    achievements: [],
    publications: [],
    affiliations: [],
  },

  // 32
  {
    id: 32,
    name: "Roshan Alex",
    designation: "B.Tech Faculty",
    profilePhoto: Roshan,
    aboutMe:
      'Roshan Alex works as a B.Tech Faculty in Msigma Gokulam and is skilled in the power system backed by his experience in the field and having an M.Tech degree in the field. He has worked as a power system engineer and as a teacher, where these experiences add to developing a professional atmosphere in the Electrical and Electronics Engineering department.',
    academics: [
      {
        qualifications: "M.Tech in Power Systems ",
        institute: "College of Engineering, Trivandrum",
      },
      {
        qualifications: "B.Tech in Electrical and Electronics Engineering",
        institute: "TKM College of Engineering, Kollam",
      },
      
    ],
    experience: [
      
      {
        company: "Tesla Engineers (P) Ltd.",
        position: " Power System Engineer",
        details:
          "Roshan Alex has two years of experience as a Power System Engineer at Tesla Engineers Private Limited where he was able to practice the learned principles and attain industrial experience. This made him efficient in learning from the modern industry and developing a sense of inquisitive mind.",
      },
      {
        company: "College of Engineering Trivandrum (CET) ",
        position: "Faculty",
        details:
          "Roshan has been a faculty in the College of Engineering, Thiruvananthapuram for two years which was useful for him in developing the skills required in the academic realm. This also gave him professional training in dealing with the students and preparing for the classes.",
      },
      {
        company: "MSigma Gokulam",
        position: "B.Tech Faculty",
        details:
          "Roshan Alex works in the Electrical and Electronics Engineering Department at Msigma Gokulam where he contributes to the institution’s development. He has been an active tutor and is known for his acceptance among the students due to his approach towards the subject.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "GATE",
        description:
          "GATE Rank Around 6000",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 33
  {
    id: 33,
    name: "Harikrishnan",
    designation: "Chief Innovation Officer",
    profilePhoto: hari,
    aboutMe:
      'Harikrishnan V.S. is the Chief Innovation Officer at Msigma Gokulam where he designs novel strategies for the institution’s upliftment. Worked in different companies, he has the caliber to come up with ideas that match contemporary situations and adapt to situations. ',
    academics: [
      {
        qualifications: "Bachelor of Computer Applications ",
        institute: "SRM University",
      },
    ],
    experience: [
      {
        company: "WorkRe",
        position: "Head of Mobile Development",
        details:
          "Harikrishnan served at WorkRe as the head of mobile engineering where he concentrated on developing innovative solutions for the customers. His period in the company was beneficial in developing an innovative mindset and finding answers to the requirements. ",
      },
      {
        company: "Deloitte",
        position: "Automation Engineer",
        details:
          "His work in Deloitte centred on analysing different data for testing the efficiency and quality of the information. Moreover, this also aided him in detecting the possible threats and finding opportunities for individual and professional development.",
      },
      {
        company: "MSigma Gokulam",
        position: "Chief Innovation Officer",
        details:
          "Harikrishnan contributes to Msigma Gokulam through his role as a Chief Innovation Officer and has introduced the institution to make changes in the internal system. He is the mastermind behind Msigma’s technical growth and continues to generate ideas that help it advance beyond expectations.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Major achievements",
        description:
          "Winner of Startup Weekend Chennai in 2020.",
      },
    ],
    publications: [],
    affiliations: [],
  },

  // 34
  {
    id: 34,
    name: "Anoop Baby",
    designation: "Mentor and GATE Faculty",
    profilePhoto: anoop,
    aboutMe:
      'Anoop Baby has been working as a Mentor and GATE Faculty at Msigma Gokulam where he has the opportunity to guide both the institution and students to success. He is experienced as a GATE faculty and is also comfortable with professional business spaces, having exposure to operations.   ',
    academics: [
      {
        qualifications: "B.Tech",
        institute: "Government Engineering College, Thrissur",
      },
    ],
    experience: [
      {
        company: "Manifold Kerala",
        position: "Junior Faculty and Content Creator",
        details: "Anoop was in Manifold Kerala as a junior faculty and content creator where he got exposure to creating content for students and getting teaching practice. Being a reputed institution, it gave him the necessary qualities to excel in the field and develop a string network for individual and professional development. ",
      },
      {
        company: "MSigma Gokulam",
        position: "Mentor and GATE Faculty",
        details:
          "At Msigma Gokulam, Anoop concentrates on providing valuable advice to the students and in clearing their doubts. He also works on generating the ideal content by sorting the best quality notes and making them easily accessible for the students.",
      },
    ],
    expertise: [],
    achievements: [
      {
        achievement: "Notable Achievements in GATE",
        description:
          "AIR - 1932 in the year 2024",
      },
    ],
    publications: [],
    affiliations: [],
  },

];
